import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';

const SymptomCardSection = ({
  title, answers, questions, onAnswer,
}) => {
  const [optionLabels, setOptionLabels] = useState(null);
  useEffect(() => {
    const value = questions[0].options.map((option) => option.option_text);
    setOptionLabels(value);
  }, [questions]);
  return optionLabels === null ? null : (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: '#93638b' }}>
              <Typography variant="h5">
                {title}
              </Typography>
            </TableCell>
            {
              optionLabels.map((optionLabel) => (
                <TableCell key={optionLabel} align="center">
                  <Typography>
                    {optionLabel}
                  </Typography>
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            questions.map((question) => (
              <TableRow key={question.id}>
                <TableCell>
                  <Typography>
                    {question.question_text}
                  </Typography>
                </TableCell>
                {
                  question.options.map((option) => (
                    <TableCell align="center" key={option.id} style={{ width: 100 }}>
                      <Radio
                        checked={answers[question.id] === option.id}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onAnswer(question.id, option.id);
                          }
                        }}
                      />
                    </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SymptomCardSection.propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.shape({ }).isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({ }),
      ),
      question_text: PropTypes.string,
    }),
  ).isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default SymptomCardSection;
