import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import HeroSection from './HeroSection';
import ProductSection from './ProductSection';
import ProductFactSection from './ProductFactSection';
import ProductDifferenceSection from './ProductDifferenceSection';
import OfferDisclaimerSection from './OfferDisclaimerSection';

const ShopPage = ({ products }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <HeroSection />
      <ProductSection ref={ref} products={products} />
      <ProductFactSection />
      <OfferDisclaimerSection />
      <ProductDifferenceSection />
    </Box>
  );
};

ShopPage.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({ }),
  ).isRequired,
};

export default ShopPage;
