import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const WellCornerMission = () => (
  <>
    <Grid>
      <Typography
        variant="h4"
        style={{
          color: '#885880',
          fontSize: '40px',
          paddingTop: '100px',
          textAlign: 'center',
        }}
      >
        wellCORNER Mission
      </Typography>
    </Grid>

    <Grid>
      <Typography
        variant="h4"
        style={{
          color: '#565656',
          fontSize: '25px',
          paddingTop: '49px',
          textAlign: 'center',
          lineHeight: '30px',
          maxWidth: '830px',
          margin: 'auto',
          marginBottom: '50px',
        }}
      >
        wellCORNER’s mission is to provide access to science-based, high-quality products that are
        specifically designed to help cancer patients and their caregivers
      </Typography>
    </Grid>
  </>
);

export default WellCornerMission;
