import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import plantImage from '../../assets/plant.jpg';

const HempOverviewNew = () => (
  <Box
    display="flex"
    flexDirection="column"
    style={{ paddingLeft: '10%', paddingRight: '10%' }}
  >
    <Grid
      container
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Grid
          item
          container
          style={{ paddingTop: 20, paddingBottom: 20 }}
          direction="row"
          spacing={9}
          justify="space-evenly"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6} style={{ padding: '36px 20px' }}>
            <Typography
              style={{ color: '#885880', fontSize: '30px', marginBottom: '19px' }}
              variant="h4"
            >
              What is Hemp?
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px', marginBottom: '30px' }}>
              {/* eslint-disable-next-line max-len */}
              Hemp and marijuana are both varieties of the cannabis sativa plant. The two most commonly
              known ingredients in the cannabis plant are cannabidiol (CBD) and tetrahydrocannabinol
              (THC).
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px', marginBottom: '30px' }}>
              {/* eslint-disable-next-line max-len */}
              These compounds have different effects on the human body. Marijuana contains high levels of
              {/* eslint-disable-next-line max-len */}
              THC, the ingredient that has a psychological effect and creates a mind-altering “high.”
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px', marginBottom: '30px' }}>
              {/* eslint-disable-next-line max-len */}
              Hemp is the term used to classify varieties of the cannabis plant that contains 0.3% or less
              of THC content (by dry weight). While containing very low levels of THC, hemp contains
              higher concentrations of other cannabinoids such as CBD and Cannabigerol (CBG).
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              alt="plant"
              src={plantImage}
              width="100%"
              style={{
                height: '400px',
                maxWidth: '486px',
                marginBottom: '30px',
              }}
            />
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px' }}>
              <strong style={{ color: '#885880' }}>Cannabidiol (CBD):</strong>
              {' '}
              a non-psychologically active
              cannabinoid, so there’s less possibility
              {/* eslint-disable-next-line max-len */}
              that it will cause the same mental confusion, drowsiness or hallucinations that often come
              {/* eslint-disable-next-line max-len */}
              with THC. CBD is being studied to understand potential benefits in a variety of different
              conditions.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default HempOverviewNew;
