import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ItemQuantity from './ItemQuantity';

const MobileCartPage = ({
  cart, onCartChange,
}) => (
  <>
    <TableContainer>
      <Table style={{ minWidth: 280 }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
                  cart.map((cartItem, index) => (
                    <TableRow key={cartItem.variant.id}>
                      <TableCell>
                        <Grid
                          item
                          container
                          direction="column"
                          justify="center"
                          alignItems="stretch"
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item xs={4}>
                              <img
                                alt={cartItem.variant.altText}
                                src={cartItem.variant.image.src}
                                width="100%"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Grid item>
                                <Typography variant="h6">
                                  {cartItem.product.title}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {cartItem.variant.title}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                direction="column"
                                justify="space-between"
                                alignItems="flex-start"
                              >
                                <Typography display="inline" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                  {`MSRP: $${cartItem.variant.compareAtPrice}`}
                                </Typography>
                                <Typography display="inline" variant="h6" style={{ color: '#D31C5C' }}>
                                  {`Your price: $${cartItem.variant.price}`}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                onClick={() => {
                                  const updatedCart = cart.filter(({ variant }) => (
                                    cartItem.variant.id !== variant.id
                                  ));
                                  onCartChange(updatedCart);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={4} />
                            <ItemQuantity
                              displayQuantityLabel
                              onSubtract={() => {
                                const updatedCart = [...cart];
                                const value = (updatedCart[index].quantity - 1);
                                if (value > -1) {
                                  updatedCart[index].quantity = value;
                                  onCartChange(updatedCart);
                                }
                              }}
                              quantity={cartItem.quantity}
                              onAdd={() => {
                                const updatedCart = [...cart];
                                const value = updatedCart[index].quantity + 1;
                                updatedCart[index].quantity = value;
                                onCartChange(updatedCart);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ))
                }
        </TableBody>
      </Table>
    </TableContainer>
  </>

);

MobileCartPage.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
  onCartChange: PropTypes.func.isRequired,
};

export default MobileCartPage;
