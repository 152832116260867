import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../../common/Api';

const useQuery = () => new URLSearchParams(useLocation().search);

const useStyles = makeStyles((theme) => ({
  paddingStyle: {
    padding: '4%',
    [theme.breakpoints.up('sm')]: {
      padding: '8%',
    },
    [theme.breakpoints.up('md')]: {
      padding: '10%',
    },
  },
}));

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  return (
    <Box className={classes.paddingStyle}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12} sm={10}>
          <Card>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                Api.resetPassword(password, query.get('token'))
                  .then(() => history.push('/home'));
              }}
            >
              <CardHeader title="Reset Password" />
              <CardContent>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  required
                  onChange={(event) => setPassword(event.target.value)}
                />
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Reset Password
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPasswordPage;
