import React from 'react';
import PropTypes from 'prop-types';
import SymptomCard from './SymptomCard';
import OtherCard from './OtherCard';

const SurveyCard = ({ answers, surveyPage, onAnswer }) => {
  const key = Object.keys(surveyPage.questions[0])[0];
  const question = surveyPage.questions[0][key][0];
  switch (surveyPage.category) {
    case 'has_any':
    case 'severe':
    case 'distress':
      return (
        <SymptomCard
          answers={answers}
          prompt={surveyPage.header_text}
          questions={surveyPage.questions}
          onAnswer={onAnswer}
        />
      );
    case 'none':
      return (
        <OtherCard
          answer={answers[question.id]}
          question={question}
          onAnswer={onAnswer}
        />
      );
    default:
      return null;
  }
};

SurveyCard.propTypes = {
  answers: PropTypes.shape({ }).isRequired,
  surveyPage: PropTypes.shape({
    category: PropTypes.string,
    header_text: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({ }),
    ).isRequired,
  }).isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default SurveyCard;
