/* eslint-disable max-len */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const PrivacyPolicy = () => {
  const history = useHistory();
  return (

    <Box padding={4}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography
              style={{ color: '#93638b' }}
              align="center"
              variant="h4"
            >
              WellCorner Privacy Policy
            </Typography>
          </Grid>
          <Grid item>
            <p className="p2">
              <span className="s1">
                <strong>Effective Date</strong>
                : July 18,2022
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                Cornerstone Specialty Network, LLC (&ldquo;
                <span>WellCorner</span>
                ,&rdquo; &ldquo;
                <strong>we</strong>
                ,&rdquo; &ldquo;
                <strong>us</strong>
                &rdquo; or &ldquo;
                <strong>our</strong>
                {/* eslint-disable-next-line max-len */}
                &rdquo;) recognizes the importance of protecting the privacy of our online visitors. It is our intent to balance our legitimate business interests in collecting and using information received from and about you with your reasonable expectations of privacy. The following privacy policy (&ldquo;
                <strong>Privacy Policy</strong>
                {/* eslint-disable-next-line max-len */}
                &rdquo;) is the way we handle information learned about you from your visits to our website available at&nbsp;
                <a href="http://www.wellcorner.com">
                  <span className="s2">www.wellcorner.com</span>
                </a>
                &nbsp;
                (the &ldquo;
                <strong>Site</strong>
                &rdquo;).
              </span>
            </p>
            <p className="p2">
              <span className="s3">
                <strong>PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY.</strong>
                {/* eslint-disable-next-line max-len */}
                  &nbsp; When you submit information to or through the Site, you consent to the collection and processing of your information as described in this Privacy Policy. By using the Site, you accept the terms of this Privacy Policy and our &nbsp;
              </span>
              <span className="s3">
                <Link
                  href="#/terms"
                  onClick={() => history.push('/terms')}
                  style={{
                    color: '-webkit-link',
                    textDecoration: 'underline',
                  }}
                >
                  Terms of Use
                </Link>
                {/* eslint-disable-next-line max-len */}
                &nbsp;, and consent to our collection, use, disclosure and retention of your information as described in this Privacy Policy.
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>
                  Personal Information
                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                {/* eslint-disable-next-line max-len */}
                WellCorner collects personally identifiable information from you, which may include first name,
                {/* eslint-disable-next-line max-len */}
                last name, email address, employer name, physical address, city/town, state/province,
                zip/postal code, credit card number,
                {/* eslint-disable-next-line max-len */}
                CVV, date of birth, and phone number. This information is collected and stored electronically and physically.
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>
                  Information Collected Automatically
                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <p className="p3">
              <span className="s1">
                {/* eslint-disable-next-line max-len */}
                Information about your computer hardware and software may automatically be collected by WellCorner when you use the Site. This information may include: your IP address, browser type, domain names, access times, referring website addresses and other technical information such as protocol status and substatus, bytes sent and received, and server information. We may also collect information about how you interact with the Site, such as when you watch our videos on the Site. This information is used by us for our business purposes, including for the operation and improvement of the Site, for technical troubleshooting, to maintain quality of the Site and to provide general statistics regarding use of the Site.
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                <strong>
                  Cookies and Other Information Collection Tools
                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <p className="p5">
              <span className="s1">
                <strong>
                  <em>What are cookies?</em>
                </strong>
                &nbsp;
              </span>
              <span className="s3">
                {/* eslint-disable-next-line max-len */}
                A cookie is a small file containing a string of characters that is sent to your computer when you visit a website or use an online service. When you visit the website or use the service again, the cookie allows that website or online service to recognize your browser or device. Cookies may store unique identifiers, user preferences and other information.
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>
            <p className="p6">
              <span className="s1">
                <strong>
                  <em>Duration of Cookies.&nbsp;</em>
                </strong>
                We may use &ldquo;session cookies&rdquo; or &ldquo;persistent
                {/* eslint-disable-next-line max-len */}
                cookies.&rdquo; Session cookies are temporary and expire once you close your browser or once your session ends. Persistent cookies remain on your device for much longer or until you or your browser erase them. Persistent cookies have varying durations that are dependent on their expiration date.
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>

            <p className="p5"><span className="s1"><strong><em>What types of cookies do we use?</em></strong></span></p>
            <p className="p5"><span className="s1"><strong><em>WellCorner uses:&nbsp;</em></strong></span></p>
            <ul className="ul1">
              <li className="li5">
                <strong><em>Necessary Cookies.</em></strong>
&nbsp;
                <span className="s2">These cookies are necessary for you to browse the Site and use its features, such as accessing secure areas of the Site. Without these cookies, certain aspects of the Site may not be available to you.</span>
              </li>
              <li className="li5">
                <strong><em>Preferences Cookies.</em></strong>
&nbsp;
                <span className="s2">These cookies collect information about how you have used the Site in the past and allow the Site to remember the choices you have made. These cookies allow us to improve how the Site works for you and tailor the Site to your preferences</span>
              </li>
              <li className="li5">
                <strong><em>Statistics Cookies.</em></strong>
&nbsp;
                <span className="s2">These cookies collect information about how you use the Site, such as which pages you most often visit on the Site, the time you spend on the Site, which links you click on, and any issues encountered. These cookies help us understand how visitors interact with the Site. All information collected is aggregated and do not identify you.</span>
              </li>
              <li className="li5">
                <strong><em>Marketing Cookies.</em></strong>
&nbsp;
                <span className="s2">These cookies track your online activity to help deliver advertisements that are relevant to you and your interests and measure the effectiveness of the advertising campaigns. They also limit how many times you see a certain advertisement. We may share the information collected through these cookies with others, such as advertisers.</span>
              </li>
            </ul>

            <p className="p5">
              <span className="s3">
                <strong>
                  <em>Why do we use them?</em>
&nbsp;
                </strong>
              </span>
              <span className="s4">&nbsp;</span>
              <span className="s3">
                {/* eslint-disable-next-line max-len */}
                Cookies help us improve the Site by providing us with information about which parts of the Site are most popular, enabling us to analyze technical and navigational information about the Site, and helping us to detect and prevent fraud. We also use cookies and other data collection tools (such as web beacons and server logs) to help improve your experience with the Site. For example, we use Google Analytics to help analyze how users use the Site. These tools use cookies to collect standard Internet log information and visitor behavior information in an anonymous form. The information generated by the cookie about your use of the Site (including IP address) is transmitted to our data collection tool service providers. This information is then used by us to evaluate visitors’ use of the Site and to compile statistical reports on website activity for WellCorner. If you would like to opt-out from the use of your information by Google analytics, you may use
              </span>
              &nbsp;
              <a href="https://tools.google.com/dlpage/gaoptout/">
                <span className="s2">Google&rsquo;s analytics opt-out browser add-on</span>
              </a>
              &nbsp;
              designed for this purpose.
              <span className="Apple-converted-space">&nbsp;</span>
            </p>
            <p className="p4">
              <span className="s1">
                <strong>
                  <em>How do I control cookies?</em>
&nbsp;
                </strong>
                {/* eslint-disable-next-line max-len */}
                Web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to manage and delete cookies,
                visit &nbsp;
                <a href="https://www.allaboutcookies.org/">
                  <span className="s2">www.allaboutcookies.org</span>
                </a>
                {/* eslint-disable-next-line max-len */}
                . Some web browsers provide settings that allow a user to reject cookies or to alert a user when a cookie is placed on the user’s computer, tablet or mobile device. Most mobile devices also offer settings to reject mobile device identifiers. Although users are not required to accept cookies or mobile device identifiers, blocking or rejecting them may prevent access to some features available through the Site.&nbsp;
              </span>
            </p>
            <p className="p4">
              <span className="s1">
                <strong>How Information is Used</strong>
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                We may use the information we collect
                for any of the following purposes:
              </span>
            </p>
            <ul className="ul1">
              <li className="li2">
                <span className="s1">to provide the Site to you and to improve the Site;</span>
              </li>
              <li className="li2">
                <span className="s1">to operate and improve the products and services we offer;</span>
              </li>
              <li className="li2">
                <span className="s1">for archival and backup purposes in connection with the provision of the Site;</span>
              </li>
              <li className="li2">
                <span className="s1">for research and analysis purposes;</span>
              </li>
              <li className="li2">
                <span className="s1">
                  to send marketing information, such as e-blasts;
                  <span className="Apple-converted-space">&nbsp;</span>
                </span>
              </li>
              <li className="li2">
                <span className="s1">to fulfil your requests, such as those related to ordering and payment, including when registering for an event or purchasing a report; and</span>
              </li>
              <li className="li2">
                <span className="s1">to communicate with you.</span>
              </li>
            </ul>
            <p className="p2">
              <span className="s1">
                <strong>
                  Sharing of Information
                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <ul className="ul1">
              <li className="li2">
                <span className="s1">
                  <strong>
                    With Third Party Service Providers Performing Services on Our Behalf.
                    <span className="Apple-converted-space">&nbsp;</span>
                  </strong>
                  {/* eslint-disable-next-line max-len */}
                  We may share your personal information with our service providers to perform the functions for which we engage them. For example, we may use third parties to host the Site or assist us in providing functionality on the Site, provide data analysis and research Site, to send out email updates about the Site or remove repetitive information from our user lists.
                  <span className="Apple-converted-space">&nbsp;</span>
                </span>
              </li>
              <li className="li2">
                <span className="s1">
                  <strong>
                    For Legal Purposes.
                  </strong>
                </span>
                <span className="s3">We also may share information that we collect from users as needed to enforce our rights, protect our property or protect the rights, property or safety of others, or as needed to support external auditing, compliance and corporate governance functions. We will disclose personal information as we deem necessary to respond to a subpoena, regulation, binding order of a data protection agency, legal process, governmental request or other legal or regulatory process. We may also share personal information as required to pursue available remedies or limit damages we may sustain.</span>
              </li>
              <li className="li5">
                <span className="s3">
                  <strong>Changes of Control.</strong>
                  &nbsp;
                  {/* eslint-disable-next-line max-len */}
                  We may transfer or assign information, including your personal information, in connection with a merger, acquisition, reorganization, liquidation, change in control or other sale by or of us or any affiliated entity (in each case whether in whole or in part).&nbsp;
                </span>
              </li>
              <li className="li5">
                <span className="s1">
                  <strong>Changes of Control</strong>
                  . We may transfer information, including your personal information,
                  in connection with a change of ownership or control by or of us or
                  any affiliated entity (in each case whether in whole or in part).
                </span>
              </li>
            </ul>
            <p className="p4">
              <span className="s1">WellCorner does not share your personal information with third parties for those third parties’ direct marketing purposes.</span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>Links to External Websites</strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                {/* eslint-disable-next-line max-len */}
                Our Site may contain links to third party websites. Any access to and use of such third-party websites is not governed by this Privacy Policy, but is instead governed by the privacy policies of those third party websites, and we are not responsible for the information practices of such third party websites.
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>
                  Security Used &amp; Retention of Personal Information
                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">WellCorner uses reasonable security measures designed to prevent unauthorized intrusion to the Site and the alteration, acquisition or misuse of personal information, however, we will not be responsible for loss, corruption or unauthorized acquisition or misuse of personal information that you provide through the Site that is stored by us, or for any damages resulting from such loss, corruption or unauthorized acquisition or misuse. We will retain your personal information for as long as necessary to fulfill the purpose for which it was collected, or as required by applicable laws or regulation.</span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>Do Not Track</strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                {/* eslint-disable-next-line max-len */}
                Our Site does not currently take any action when it receives a Do Not Track request. Do Not Track is a privacy preference that you can set in your web browser to indicate that you do not want certain information about your webpage visits collected across websites when you have not interacted with that service on the page. For details, including how to turn on Do Not Track, visit
                &nbsp;
                <a href="http://www.donottrack.us">
                  <span className="s6">www.donottrack.us</span>
                </a>
                .
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>
                  Children
                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                {/* eslint-disable-next-line max-len */}
                We do not knowingly collect or maintain personal information from any person under the age of thirteen. No parts of our Site are directed to or designed to attract anyone under the age of thirteen.
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>Contact Information; Questions and Changes in Information</strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                {/* eslint-disable-next-line max-len */}
                If you have any questions regarding this Privacy Policy or to request changes to your personal information, please contact us at
                &nbsp;
                <a href="mailto:info@cornerstone.com">
                  <span className="s2">info@cornerstone.com</span>
                </a>
                .
                <span className="Apple-converted-space">&nbsp;</span>
              </span>
            </p>
            <p className="p2">
              <span className="s1">
                <strong>Notification of Changes</strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">Any changes to our Privacy Policy will be posted to this page so users are always aware of the information we collect and how we use it. Accordingly, please refer back to this Privacy Policy frequently as it may change.</span>
            </p>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
