import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const ProductFactSection = () => (
  <Box bgcolor="#b1d3d2" padding={4}>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={6}>
        <Grid item>
          <Typography align="center" variant="h4">
            All Papa &amp; Barkley products:
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start"
        >

          <Grid item xs={12} sm={9}>
            <List>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Contain clean ingredients
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Are whole plant infused extracts
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Triple-tested by 3rd-party laboratories
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Made with hemp using organic and regenerative farming products
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Are solventless extracts, derived with no chemicals
                  or harsh extraction methods
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  </Box>
);

export default ProductFactSection;
