import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SymptomCardSection from './SymptomCardSection';

const SymptomCard = ({
  answers, prompt, questions, onAnswer,
}) => {
  const [symptomQuestions, setSymptomQuestions] = useState([]);
  useEffect(() => {
    const value = questions.filter((question) => {
      const key = Object.keys(question)[0];
      return question[key].length > 0;
    });
    setSymptomQuestions(value);
  }, [questions]);
  return (
    <Card style={{ width: '100%' }}>
      <CardHeader
        subheader={prompt}
        title="During the past week"
        titleTypographyProps={{ style: { color: '#93638b' }, variant: 'h5' }}
        subheaderTypographyProps={{ color: 'secondary', variant: 'h4' }}
      />
      <CardContent>
        {
          symptomQuestions.length === 0 ? (
            <Typography>You have not indicated any symptoms.</Typography>
          ) : symptomQuestions.map((question) => {
            const key = Object.keys(question)[0];
            return (
              <SymptomCardSection
                key={key}
                title={key}
                answers={answers}
                questions={question[key]}
                onAnswer={onAnswer}
              />
            );
          })
        }
      </CardContent>
    </Card>
  );
};

SymptomCard.propTypes = {
  answers: PropTypes.shape({ }).isRequired,
  prompt: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({ }),
  ).isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default SymptomCard;
