import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    maxWidth: '950px',
    margin: '0 auto 110px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  button: {
    width: '250px',
    height: '66px',
    fontSize: '20px',
    textTransform: 'inherit',
    borderRadius: '10px',
    margin: '0 50px 0 0',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
}));

const Cannabidiol = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <Grid item>
        <Typography
          align="center"
          color="secondary"
          variant="h4"
          style={{
            fontSize: '40px',
            padding: '70px 0',
          }}
        >
          Cannabidiol - CBD
        </Typography>
      </Grid>
      <Grid>
        <Typography
          align="center"
          color="secondary"
          variant="h4"
          style={{
            fontSize: '25px',
            padding: '0 0 100px',
            color: '#565656',
            maxWidth: '600px',
            margin: 'auto',
          }}
        >
          {/* eslint-disable-next-line max-len */}
          If you have interest in hemp and cannabidiol (CBD) products, understanding the differences between
          all of these products is important.
        </Typography>
      </Grid>
      <Grid className={classes.content}>
        <Grid>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => history.push('/shop')}
            className={classes.button}
          >
            Explore Products
          </Button>
        </Grid>
        <Grid>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => history.push('/register')}
            className={classes.button}
          >
            Join the community
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Cannabidiol;
