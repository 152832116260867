import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Carousel } from 'react-responsive-carousel';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ItemQuantity from './ItemQuantity';
import { freeShippingValue } from '../../constants';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '90%',
    borderCollapse: 'collapse',
  },
  border: {
    padding: '5px',
    border: '1px',
    borderStyle: 'solid',
    borderRadius: 1,
    borderColor: ' #B8B8B8',
  },
  td: {
    padding: '5px',
    textAlign: 'center',
    border: '1px',
    borderStyle: 'solid',
    borderRadius: 1,
    borderColor: ' #B8B8B8',
  },
  textColor: {
    color: 'red',
  },
  productPage: {
    maxWidth: '1360px',
    padding: '0 15px',
    margin: '60px auto 0',
    [theme.breakpoints.down('sm')]: {
      margin: '25px auto 0',
    },
  },
}));
const Product = ({
  product,
  onAddToCart,
}) => {
  const history = useHistory();
  const initialOptions = product.options.map((option) => option.values[0].value);
  const [variant, setVariant] = useState(product.variants[0]);
  const [options, setOptions] = useState(initialOptions);
  const [internalQuantity, setInternalQuantity] = useState(1);
  const { title } = product;
  const classes = useStyles();

  let productDescription;
  if (title === 'Releaf Balm') {
    productDescription = (
      <>
        <p>
          <strong>Great for tension and headaches from daily stress.</strong>
        </p>
        <p>
          {/* eslint-disable-next-line max-len */}
          Great for tension and headaches from daily stress. Our soothing, relieving salve is made from
          {/* eslint-disable-next-line max-len */}
          all-natural ingredients grown on USA farms, a blend of beeswax, and several healing essential oils.
          {/* eslint-disable-next-line max-len */}
          Extracts from eucalyptus, tea tree, peppermint and lavender plants offer this salve a subtly
          {/* eslint-disable-next-line max-len */}
          herbaceous scent. Once our Releaf balm is applied, an activated warmth is followed by a cooling
          {/* eslint-disable-next-line max-len */}
          sensation. Want a stronger effect? Simply apply more for additional relief. As always, it’s
          {/* eslint-disable-next-line max-len */}
          formulated without chemicals or solvents and can be delivered nationwide. Like everything at P&B,
          {/* eslint-disable-next-line max-len */}
          this salve is backed by our 100% Satisfaction Guarantee. Great for post-workout soreness, cramps,
          {/* eslint-disable-next-line max-len */}
          stretching, and muscle aches. Made from: All-natural ingredients grown on USA farms. A blend of
          beeswax and several healing essential oils Peppermint, tea tree, and lavender are great
          {/* eslint-disable-next-line max-len */}
          anti-inflammatories, can help with stress and sleep. Size 15ML, 50ML Serving Size 1 Tbsp Ingredients
          Hemp Extract, Coconut Oil, Beeswax, Essential Oil Blend (Eucalyptus, Tea Tree, Peppermint,
          Lavender), Vitamin E Oil, Natural Terpenes Phytocannabinoids 180mg (15ml), 600mg (50ml)
          {/* eslint-disable-next-line max-len */}
          Manufacturing Manufactured in a facility that also processes nuts. USDA - All products contain less
          than 0.3% THC.
          <br />
          <a href="https://www.papaandbarkleycbd.com/hemp-balm-coa" style={{ fontSize: '15px', textDecorationLine: 'none', color: '#000000' }}>Certificate of Analysis</a>
        </p>
      </>
    );
  } else if (title === 'Releaf Capsules') {
    productDescription = (
      <>
        <p>
          <strong>
            Ideal for better sleep, calm mood, and relaxation.
          </strong>
        </p>
        <p>
          Our CBD Capsules are made from simple, all-natural ingredients grown on Colorado farms,
          this precise,
          full-strength 30mg single-dose capsules offer whole body relief, stat.
          Take one (1) vegetable capsule, preferably with food. Repeat as needed.
          As always, our capsules are formulated without chemicals or solvents
          and can be delivered nationwide.
          Like everything at P&amp;B, these capsules are backed by our 100% Satisfaction Guarantee.
        </p>
        <ul>
          <li>Delivered nationwide.</li>
          <li>
            Like everything at P&amp;B,
            our Releaf Capsules is backed by our 100% Satisfaction Guarantee.
          </li>
        </ul>
        <p><strong>Made from:</strong></p>
        <ul>
          <li>Hemp extract and coconut oil, and formulated without chemicals or solvents.&nbsp;</li>
          <li>Gluten-free, soy-free, and vegan.</li>
        </ul>
        <table className={classes.table}>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Size</strong></td>
            <td className={classes.border}>30 Ct</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Serving Size</strong></td>
            <td className={classes.border}>1 capsule</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Ingredients </strong></td>
            <td className={classes.border}>
              Coconut Oil, Hemp Extract
            </td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Phytocannabinoids </strong></td>
            <td className={classes.border}>900 mg per bottle</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Manufacturing</strong></td>
            <td className={classes.border}>Manufactured in a facility that also processes nuts.</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>USDA - </strong></td>
            <td className={classes.border}>All products contain less than 0.3% THC.</td>
          </tr>
        </table>
        <p>
          <br />
          <a href="https://www.papaandbarkleycbd.com/capsules-coa">Certificate of Analysis</a>
        </p>
      </>
    );
  } else if (title === 'Releaf Drops') {
    productDescription = (
      <>
        <p>
          <strong>
            Daily use for stress relief and relaxation.
          </strong>
        </p>
        <p>
          Our CBD drops are made using our whole plant infusion process from simple,
          all-natural ingredients grown on Colorado farms. Our fastest-metabolizing formula,
          it comes with a measured dropper for precise dosing, making it easy to find your
          individually preferred potency.
          Plus, it&rsquo;s formulated from the whole plant without chemicals or solvents,
          resulting in an earthy and rich flavor.
        </p>
        <ul>
          <li>Our fastest-metabolizing formula!</li>
          <li>
            Comes with a measured dropper—so you can find your perfect dose.
          </li>
          <li>
            Available in natural and lemongrass ginger flavor.
          </li>
        </ul>
        <p><strong>Made from:</strong></p>
        <ul>
          <li>Hemp & coconut oil</li>
          <li>Gluten-free, soy-free, and vegan.</li>
          <li>
            Formulated from the whole plant without chemicals or solvents.
          </li>
        </ul>
        <table className={classes.table}>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Size</strong></td>
            <td className={classes.border}>15ml, 30ml</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Serving Size</strong></td>
            <td className={classes.border}>1ml (one full dropper)</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Ingredients </strong></td>
            <td className={classes.border}>
              MCT Oil, Hemp Extract
            </td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Phytocannabinoids </strong></td>
            <td className={classes.border}>450mg CBD (15ml), 900mg CBD (30ml)</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>Manufacturing</strong></td>
            <td className={classes.border}>Manufactured in a facility that also processes nuts.</td>
          </tr>
          <tr className={classes.border}>
            <td className={classes.td}><strong>USDA - </strong></td>
            <td className={classes.border}>All products contain less than 0.3% THC.</td>
          </tr>
        </table>
        <p>
          <br />
          <strong>Certificate of Analysis:&nbsp;</strong>
          <a href="https://www.papaandbarkleycbd.com/coa-of-mild-strength-releaf-drops-15mg">Natural Flavor 15ml</a>
          ,&nbsp;
          <a href="https://www.papaandbarkleycbd.com/hemp-drops-coa-30ml">Natural Flavor 30ml</a>
          ,&nbsp;
          <a href="https://www.papaandbarkleycbd.com/coa-of-mild-strength-releaf-drops-15mg">Lemongrass Ginger 15ml</a>
          ,&nbsp;
          <a href="https://www.papaandbarkleycbd.com/hemp-drops-coa-30ml">Lemongrass Ginger 30ml</a>
        </p>
      </>
    );
  } else {
    productDescription = product.description;
  }

  useEffect(() => {
    const selectedVariant = product.variants.find((value) => (
      options.every((option) => (
        value.selectedOptions.some((selectedOption) => selectedOption.value === option)
      ))
    ));
    setVariant(selectedVariant);
  }, [options]);
  return (
    <Grid container style={{ paddingTop: 20 }}>
      <Grid
        item
        sm={6}
        xs={12}
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{ padding: '0 75px 20px 20px' }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          axis="horizontal"
          showStatus={false}
          showIndicators
          showThumbs={false}
          showArrows
          className={classes.carousel}
        >

          {product
            && product.images.map((productImg) => <img key={productImg.title} alt={productImg.title} src={productImg.src} height="100%" />)}
        </Carousel>
        {product.title.toLowerCase().indexOf('drop') > -1 && (
        <Grid item>
          <Typography
            color="primary"
            variant="h4"
            align="center"
            style={{ padding: '1rem 0rem' }}
          >
            NEW! CBD Releaf Drop Recipes
          </Typography>
          <Typography
            align="center"
          >
            <Button
              align="center"
              color="primary"
              style={{ width: 150 }}
              onClick={() => history.push('/recipes')}
              variant="contained"
            >
              Click Here
            </Button>
          </Typography>
        </Grid>
        )}
      </Grid>
      <Grid item sm={6} xs={12}>
        <Grid
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography
              color="primary"
              variant="h4"
              style={{ fontSize: '40px' }}
            >
              {product.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ textDecorationStyle: 'solid', color: '#565656', fontSize: '15px' }}>
              {`MSRP: $${variant.compareAtPrice}`}
            </Typography>
            <Grid item>
              <Typography display="inline" variant="h6" style={{ color: '#DD2172' }}>
                {` $${variant.price} `}
              </Typography>
              <Typography display="inline" style={{ color: '#DD2172', fontWeight: 'bold', fontSize: '15px' }}>
                - 10% Discount
              </Typography>
            </Grid>
          </Grid>
          {
            product.variants.length === 1 ? null : product.options.map((option, index) => (
              <Grid item key={option.id}>
                <FormControl style={{ minWidth: 117 }}>
                  <InputLabel style={{ color: '#565656', fontSize: '20px' }}>{option.name}</InputLabel>
                  <Select
                    value={options[index]}
                    style={{
                      marginTop: '30px',
                      border: '1px solid rgba(0, 0, 0, 0.42)',
                      padding: '6px 24px 7px 10px',
                      borderBottom: 'none',
                      height: '42px',
                    }}
                    onChange={(event) => setOptions((previousOptions) => {
                      const updatedOptions = [...previousOptions];
                      updatedOptions[index] = event.target.value;
                      return updatedOptions;
                    })}
                  >
                    {
                      option.values.map((value) => (
                        <MenuItem
                          key={value.value}
                          value={value.value}
                        >
                          {value.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            ))
          }
          <ItemQuantity
            displayQuantityLabel
            quantity={internalQuantity}
            onSubtract={() => {
              if (internalQuantity > 1) {
                setInternalQuantity(internalQuantity - 1);
              }
            }}
            onAdd={() => setInternalQuantity(internalQuantity + 1)}
          />
          <Grid item>
            <Button
              color="primary"
              style={{
                width: 160,
                height: '60px',
                fontSize: '20px',
                background: '#885880',
                borderRadius: '10px',
                textTransform: 'inherit',
                marginTop: '10px',
              }}
              onClick={() => {
                onAddToCart(product, variant, internalQuantity);
              }}
              variant="contained"
            >
              Add to Cart
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontSize: '15px', fontWeight: 'bold', marginTop: '25px', color: '#000000',
              }}
            >
              Product Description
            </Typography>
            <Typography paragraph style={{ fontSize: '15px', lineHeight: '18px', color: '#000000' }}>
              {productDescription}
            </Typography>
          </Grid>
          <Grid item>
            <Typography paragraph style={{ fontSize: '15px', lineHeight: '18px', color: '#000000' }}>
              *These statements have not been evaluated by the Food and Drug Administration.
              This product is not intended to diagnose, treat, cure, or prevent any disease.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" style={{ fontSize: '15px', color: '#000000' }}>
              Shipping
            </Typography>
            <Typography variant="h6" style={{ color: '#D31C5C', fontSize: '17px', margin: '15px 0' }}>
              {`FREE Shipping on orders over $${freeShippingValue}`}
            </Typography>
            <Typography paragraph style={{ fontSize: '15px', color: '#000000' }}>
              Shipping will be calculated at the next step
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    variants: PropTypes.arrayOf(
      PropTypes.shape({ }),
    ),
  }).isRequired,
  onAddToCart: PropTypes.func.isRequired,
};

const ProductPage = ({
  products,
  onAddToCart,
  cart,
}) => {
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const result = products.find(({ handle }) => handle === id);
    const updatedProduct = result === undefined ? null : result;
    setProduct(updatedProduct);
  }, [products, id]);
  const classes = useStyles();

  return (
    <Box className={classes.productPage}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            onClick={() => history.push('/shop')}
            startIcon={<ArrowBack />}
            color="primary"
            style={{
              color: '#885880',
              fontSize: '16px',
              textDecorationLine: 'underline',
              textTransform: 'inherit',
            }}
          >
            Return to Shop
          </Button>
        </Grid>
        <Grid item xs={12}>
          {product === null ? null
            : (
              <Product
                cart={cart}
                product={product}
                onAddToCart={onAddToCart}
              />
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

ProductPage.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({ }),
  ).isRequired,
  onAddToCart: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
};

export default ProductPage;
