import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import GaApi from '../../analytics/gaApi';

const SurveyHistoryPage = () => {
  const history = useHistory();
  return (
    <Box
      padding={4}
      alignItems="center"
    >
      <Grid
        item
        alignItems="center"
        container
        justify="center"
        spacing={2}
        direction="column"
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography align="center" variant="h4" style={{ color: '#93638b', paddingBottom: '4%' }}>
              Surveys
            </Typography>
            <Typography align="center">
              Thank you for being part of the wellCORNER Community!
              As part of your involvement in
              this community, please complete your
            </Typography>
            <Typography display="inline" style={{ fontWeight: 'bold' }}>
              Wellness Survey
            </Typography>
            <Typography>
              This survey can be used to:
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; Track your own wellness over time
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; Print out historical surveys to review your
                  wellness with your healthcare team
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; Help other patients by providing feedback
                  about products and services
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; Influence products and product formulation
                </Typography>
              </ListItem>
            </List>
            <Typography align="center" style={{ fontWeight: 'bold' }}>
              Complete this survey each month, and after 3 months receive a free
              sample of a Papa & Barkley product
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item>
              <Card>
                <CardHeader
                  style={{ color: '#93638b' }}
                  title="My Wellness Survey"
                />
                <CardContent>
                  <Typography>
                    Help us get to know you by answering some questions about your symptom history.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      GaApi.startSurvey();
                      GaApi.sendEvent(
                        'START_SURVEY',
                        'Start Survey button pressed',
                      );
                      history.push('/survey');
                    }}
                    variant="outlined"
                  >
                    Take Survey
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => history.push('/survey-results')}
              >
                Completed Surveys
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyHistoryPage;
