import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import whiteWLogoSolo from '../../assets/whiteWLogoSolo.png';

const OfferDisclaimerSection = () => (
  <Box
    bgcolor="primary.main"
    padding={4}
  >
    <Grid
      alignItems="center"
      container
      direction="row"
      spacing={2}
      justify="center"
    >
      <Grid
        item
        xs={12}
        sm={8}
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={4}>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img alt="wellCORNER" src={whiteWLogoSolo} width={150} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography color="textSecondary" variant="h6">
            Products offered through wellCORNER MUST:
          </Typography>
          <Typography color="textSecondary">
            • Be reviewed by the wellCORNER Professional Advisory Board
          </Typography>
          <Typography color="textSecondary">
            • Have science-based formulations or design
          </Typography>
          <Typography color="textSecondary">
            • Be third-party tested for quality and purity
          </Typography>
          <Typography color="textSecondary">
            • Support your overall wellness
          </Typography>
        </Grid>
      </Grid>

    </Grid>
  </Box>
);

export default OfferDisclaimerSection;
