import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Api from '../../common/Api';
import HempOverviewNew from './HempOverviewNew';
import HempComparisonSectionNew from './HempComparisonSectionNew';
import Cannabidiol from './Cannabidiol';
import HempInterestSectionNew from './HempInterestSectionNew';
import ProductImportanceSection from './ProductImportanceSection';
import CannabinoidsWork from './CannabinoidsWork';

const CbdPage = ({ authorized, onPracticeSlug }) => {
  const [questions, setQuestions] = useState([]);
  const learnMoreAboutHempRef = useRef(null);
  const { practiceSlug } = useParams();
  const onLearnMoreAboutHempClick = () => {
    learnMoreAboutHempRef.current.scrollIntoView({ behavior: 'smooth' });
    learnMoreAboutHempRef.current.click();
  };
  useEffect(() => {
    if (practiceSlug !== undefined) {
      onPracticeSlug(practiceSlug);
    }
  }, [practiceSlug]);
  useEffect(() => {
    Api.getInteractiveQuestions()
      .then((values) => setQuestions(values));
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ maxWidth: '1360px', padding: '0 15px', margin: 'auto' }}
    >
      <Cannabidiol />
      <HempOverviewNew />
      { questions.length === 0 ? null
        : (
          <HempInterestSectionNew
            onLearnMoreAboutHempClick={onLearnMoreAboutHempClick}
            authorized={authorized}
            question={questions[0]}
          />
        ) }
      <CannabinoidsWork />
      <HempComparisonSectionNew learnMoreAboutHempRef={learnMoreAboutHempRef} />
      { questions.length === 0 ? null
        : (
          <ProductImportanceSection
            authorized={authorized}
            question={questions[1]}
          />
        ) }
    </Box>
  );
};

CbdPage.propTypes = {
  authorized: PropTypes.bool.isRequired,
  onPracticeSlug: PropTypes.func,
};

CbdPage.defaultProps = {
  onPracticeSlug: () => null,
};

export default CbdPage;
