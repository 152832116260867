import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const SymptomScale = ({
  label,
  values,
}) => (
  <Grid
    container
    direction="row"
    spacing={2}
    justify="center"
    wrap="nowrap"
  >
    <Grid item>
      <Typography
        style={{ color: '#885880', whiteSpace: 'nowrap' }}
        variant="h6"
      >
        {label}
      </Typography>
    </Grid>
    <Grid item>
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Typography style={{ whiteSpace: 'nowrap' }}>
            Not Applicable
          </Typography>
        </Grid>
        <Grid item>
          <Typography>-</Typography>
        </Grid>
      </Grid>
    </Grid>
    {
      values.map((value, index) => (
        <Grid item key={value}>
          <Grid
            alignItems="center"
            container
            direction="column"
            spacing={1}
          >
            <Grid item>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {value}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{index + 1}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))
    }
  </Grid>
);

SymptomScale.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default SymptomScale;
