import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';

const JustAddedModal = ({
  justAddedProduct,
  onClose,
  open,
}) => {
  const matchesSizeSmallUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const history = useHistory();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={matchesSizeSmallUp ? 'sm' : 'lg'}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h4" style={{ color: '#885880', fontSize: '1.7rem' }}>
            Added to Cart
          </Typography>
          <IconButton aria-label="close" onClick={onClose} style={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={4}
            container
            alignItems="center"
            justify="center"
          >
            <img
              alt={justAddedProduct.variant.altText}
              src={justAddedProduct.variant.image.src}
              width={matchesSizeSmallUp ? '100%' : '70%'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justify="space-between"
            alignItems={matchesSizeSmallUp ? 'flex-start' : 'center'}
          >
            <Grid item>
              <Typography variant="h6">
                {justAddedProduct.product.title}
              </Typography>
              <Typography>
                {justAddedProduct.variant.title}
              </Typography>
              <Typography style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'gray' }}>
                {`MSRP: $${justAddedProduct.variant.compareAtPrice}`}
              </Typography>
              <Grid item>
                <Typography display="inline" style={{ color: '#D31C5C', fontSize: '1.15rem', fontWeight: 'bold' }}>
                  {` $${justAddedProduct.variant.price} `}
                </Typography>
                <Typography display="inline" style={{ color: '#D31C5C', fontWeight: 'bold' }}>
                  - 10% Discount
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={matchesSizeSmallUp ? 'row' : 'column'}
            justify={matchesSizeSmallUp ? 'space-between' : 'center'}
            alignItems={matchesSizeSmallUp ? 'stretch' : 'center'}
            xs={12}
          >
            <Button
              fullWidth={!matchesSizeSmallUp}
              color="primary"
              onClick={() => {
                onClose();
              }}
              size="small"
            >
              Continue Shopping
            </Button>
            <Button
              fullWidth={!matchesSizeSmallUp}
              color="primary"
              onClick={() => {
                onClose();
                history.push('/cart');
              }}
              variant="contained"
              size="large"
            >
              Checkout
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

    </Dialog>
  );
};

JustAddedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  justAddedProduct: PropTypes.oneOfType([
    PropTypes.shape(
      {
        product: PropTypes.shape({ }),
        variant: PropTypes.shape({ }),
        quantity: PropTypes.number,
      },
    ),
    () => null,
  ]).isRequired,
};

export default JustAddedModal;
