import { createMuiTheme } from '@material-ui/core/styles';
import MyriadProRegular from '../fonts/Myriad-Pro-Regular.ttf';
import {
  green, purple, orange, mediumGray,
} from '../constants';

const myriadRegular = {
  fontFamily: 'Myriad',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Myriad'),
    local('Myriad-Pro-Regular'),
    url(${MyriadProRegular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      light: '#86a764',
      main: green,
      dark: '#48662b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#a55f2e',
      main: orange,
      dark: '#885880',
      contrastText: '#fff',
    },
    accent: {
      light: '#9f7999',
      main: purple,
      dark: '#5f3d59',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#fff',
    },
  },
  typography: {
    fontFamily: 'Myriad',
    h3: {
      fontFamily: 'MyriadSemibold',
    },
    h4: {
      fontFamily: 'MyriadCondensed',
    },
    h6: {
      fontFamily: 'MyriadSemibold',
    },
    button: {
      fontFamily: 'MyriadSemibold',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [myriadRegular],
        },
      },
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        height: 32,
      },
      colorPrimary: {
        backgroundColor: '#dfe6e9',
      },
      colorSecondary: {
        backgroundColor: '#dfe6e9',
      },
      barColorPrimary: {
        backgroundColor: orange,
      },
      barColorSecondary: {
        backgroundColor: mediumGray,
      },
    },
    MuiFormLabel: {
      root: {
        color: mediumGray,
      },
    },
    MuiCheckbox: {
      root: {
        color: mediumGray,
      },
    },
    MuiRadio: {
      root: {
        color: mediumGray,
      },
    },
  },
});

export default theme;
