import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Api from '../common/Api';
import logo from '../assets/wellCornerLogos/logoColor.png';

import CartWithBadge from './CartWithBadge';

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  toolbarHeader: {
    background: 'white',
    padding: '13px 15px',
    margin: 'auto',
    width: '100%',
    maxWidth: '1360px',
  },
  cbdHeader: {
    position: 'relative',
    display: 'none',
  },
  subCategory: {
    position: 'absolute',
    bottom: '-70px',
    borderRadius: '3px',
    width: '126px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  },
  subContent: {
    '&:hover': {
      display: 'block',
      backgroundColor: 'rgba(104, 146, 62, 0.04)',
    },
  },
  button: {
    minWidth: 'auto',
    marginRight: '20px',
  },
}));

const LoginControls = ({ cart }) => (
  <Grid container spacing={2} justify="flex-end">
    <CartWithBadge cart={cart} />
    <Grid
      item
      style={{
        backgroundColor: '#885880',
        height: '40px',
        width: '90px',
        borderRadius: '4px',
      }}
    >
      <a
        style={{
          fontWeight: 'bold',
          fontSize: '10px',
          textTransform: 'uppercase',
          color: 'white',
        }}
        href="https://survey.wellcorner.com/#/login"
      >
        Sign In /
      </a>
      <a
        style={{
          fontWeight: 'bold',
          fontSize: '10px',
          textTransform: 'uppercase',
          color: 'white',
        }}
        href="https://survey.wellcorner.com/#/register"
      >
        Sign Up
      </a>
    </Grid>
  </Grid>
);
LoginControls.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
};
const ProfileControls = ({ cart }) => {
  const history = useHistory();
  const matchesSizeLargeUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  return (
    <Grid
      container
      spacing={matchesSizeLargeUp ? 2 : 1}
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <CartWithBadge cart={cart} />
      <Grid item>
        <Button
          color="secondary"
          onClick={() => history.push('/surveys')}
          variant="contained"
          size={matchesSizeLargeUp ? 'medium' : 'small'}
          style={{
            fontWeight: 'bold',
            fontSize: '10px',
            textTransform: 'uppercase',
            width: '80px',
            height: '36px',
          }}
        >
          Surveys
        </Button>
      </Grid>
      <Hidden smDown>
        <Grid item>
          <Button
            color="secondary"
            size={matchesSizeLargeUp ? 'medium' : 'small'}
            onClick={() => history.push('/profile')}
            variant="contained"
            style={{
              fontWeight: 'bold',
              fontSize: '10px',
              textTransform: 'uppercase',
              width: '80px',
              height: '36px',
            }}
          >
            Profile
          </Button>
        </Grid>
      </Hidden>
      <Grid item>
        <Typography
          color="secondary"
          onClick={() => Api.logout()}
          variant="contained"
          size={matchesSizeLargeUp ? 'medium' : 'small'}
          style={{
            fontWeight: 'bold',
            fontSize: '10px',
            textTransform: 'uppercase',
          }}
        >
          Logout
        </Typography>
      </Grid>
    </Grid>
  );
};

ProfileControls.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
};
const Navbar = ({ authorized, cart }) => {
  const matchesSizeLargeUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const { pathname } = useLocation();
  useEffect(() => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  }, [pathname]);

  const [show, setShow] = useState(false);

  return (
    <>
      {/* position is fixed instead of sticky because
       ⚠️ sticky is not supported by IE 11.
       https://material-ui.com/components/app-bar/ */}
      <AppBar
        className="no-print"
        color="default"
        position="fixed"
        style={{ background: 'white', boxShadow: 'none' }}
      >
        <Toolbar className={classes.toolbarHeader}>
          <Hidden mdUp>
            <Drawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <List style={{ width: 256 }}>
                <ListItem>
                  <a href="https://www.wellcorner.com/">
                    <img
                      alt="wellcorner"
                      src={logo}
                      width={170}
                    />
                  </a>
                </ListItem>
                <ListItem onClick={() => setShow(!show)} style={{ position: 'relative', display: 'none' }}>
                  <a style={{ textDecoration: 'none', color: '#000000', fontSize: '16px' }} href="https://wellcorner.com/cbd">CBD</a>
                  {show && (
                  <Grid className={classes.subCategoryMobile}>
                    <Grid item={matchesSizeLargeUp} className={classes.subContent}>
                      <Button
                        color="primary"
                        onClick={() => history.push('/cbd')}
                        style={{ textTransform: 'inherit' }}
                      >
                        Learn CBD
                      </Button>
                    </Grid>
                    <Grid item={matchesSizeLargeUp} className={classes.subContent}>
                      <Button
                        color="primary"
                        onClick={() => history.push('/shop')}
                        style={{ textTransform: 'inherit' }}
                      >
                        Shop CBD
                      </Button>
                    </Grid>
                  </Grid>
                  )}
                </ListItem>

                <ListItem
                  button
                  component="a"
                  href={window.location.hostname === 'survey.wellcorner.com'
                    ? 'https://wellcorner.com/categories.html'
                    : 'https://dev.wellcorner.com/categories.html'}
                >
                  <ListItemText primary="wellCORNER products" />
                </ListItem>
                <ListItem>
                  <a style={{ textDecoration: 'none', color: '#000000', fontSize: '16px' }} href="https://wellcorner.com/about">About Us</a>
                </ListItem>
                <ListItem>
                  <a style={{ textDecoration: 'none', color: '#000000', fontSize: '16px' }} href="https://wellcorner.com/faq">FAQ</a>
                </ListItem>
                <ListItem>
                  <a style={{ textDecoration: 'none', color: '#000000', fontSize: '16px' }} href="https://www.wellcorner.com/glossary">Glossary</a>
                </ListItem>
                { authorized
                  ? (
                    <ListItem onClick={() => history.push('/profile')}>
                      <ListItemText primary="Update Profile" />
                    </ListItem>
                  ) : null}
              </List>
            </Drawer>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="primary"
              edge="start"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Grid container alignItems="center" style={{ position: 'relative' }}>
            <Grid item xs={12} md={8}>
              <Grid
                container
                spacing={1}
                alignItems="center"
              >
                <Hidden smDown>
                  <Grid item={matchesSizeLargeUp}>
                    <Hidden mdDown>
                      <Button color="primary">
                        <a href="https://www.wellcorner.com/">
                          <img
                            alt="wellcorner"
                            src={logo}
                            width={256}
                          />
                        </a>
                      </Button>
                    </Hidden>
                    <Hidden smDown lgUp>
                      <Button color="primary">
                        <a href="https://www.wellcorner.com/">
                          <img
                            alt="wellcorner"
                            src={logo}
                            width={235}
                          />
                        </a>
                      </Button>
                    </Hidden>
                  </Grid>
                  <Grid
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <Grid
                      item={matchesSizeLargeUp}
                      className={classes.cbdHeader}
                    >
                      <Button
                        color="primary"
                        onClick={() => setShow(!show)}
                        className={classes.button}
                      >
                        <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/cbd">CBD</a>
                      </Button>
                      {show && (
                      <Grid className={classes.subCategory}>
                        <Grid item={matchesSizeLargeUp} className={classes.subContent}>
                          <Button
                            color="primary"
                            onClick={() => history.push('/cbd')}
                            style={{ textTransform: 'inherit' }}
                          >
                            Learn CBD
                          </Button>
                        </Grid>
                        <Grid item={matchesSizeLargeUp} className={classes.subContent}>
                          <Button
                            color="primary"
                            onClick={() => history.push('/shop')}
                            style={{ textTransform: 'inherit' }}
                          >
                            Shop CBD
                          </Button>
                        </Grid>
                      </Grid>
                      )}
                    </Grid>
                    <Grid item={matchesSizeLargeUp}>
                      <Button
                        color="primary"
                        href={window.location.hostname === 'survey.wellcorner.com'
                          ? 'https://wellcorner.com/categories.html'
                          : 'https://dev.wellcorner.com/categories.html'}
                        className={classes.button}
                      >
                        wellCORNER products
                      </Button>
                    </Grid>
                    <Grid item={matchesSizeLargeUp}>
                      <Button
                        color="primary"
                        className={classes.button}
                      >
                        <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/about">About Us</a>
                      </Button>
                    </Grid>
                    <Grid item={matchesSizeLargeUp}>
                      <Button
                        color="primary"
                        className={classes.button}
                      >
                        <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/faq">FAQ</a>
                      </Button>
                    </Grid>
                    <Grid item={matchesSizeLargeUp}>
                      <Button
                        color="primary"
                        className={classes.button}
                      >
                        <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/glossary">Glossary</a>
                      </Button>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              {authorized ? <ProfileControls cart={cart} /> : <LoginControls cart={cart} />}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
};

Navbar.propTypes = {
  authorized: PropTypes.bool.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
};

export default Navbar;
