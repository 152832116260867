import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const ThankYouPage = () => {
  const history = useHistory();
  return (
    <Box padding={4}>
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h4" style={{ color: '#885880' }}>
            Thank you!
          </Typography>
        </Grid>
        <Grid item>
          <Typography paragraph align="center">
            Thank you for your submission. Please login again to keep track for your symptoms.
            This record can be printed or downloaded, and shared with your physician.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            style={{ backgroundColor: '#885880' }}
            onClick={() => {
              history.push('/surveys');
            }}
          >
            Return to Surveys
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThankYouPage;
