import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import bodyImage from '../../assets/body.png';

const CannabinoidsWork = () => (
  <Box
    display="flex"
    flexDirection="column"
    style={{ paddingLeft: '10%', paddingRight: '10%' }}
  >
    <Grid
      container
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Grid
          item
          container
          style={{ paddingTop: 20, paddingBottom: 20 }}
          direction="row"
          spacing={9}
          justify="space-evenly"
          alignItems="flex-start"
        >

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ color: '#885880', fontSize: '30px', marginBottom: '36px' }}
              variant="h4"
            >
              How do cannabinoids work?
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px' }}>
              Cannabinoids are natural chemical compounds produced by cannabis flowers, as well as
              other plants. All cannabinoids produce effects in the body by interacting with
              cannabinoid receptors, which form part of the endocannabinoid system in the human
              body.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: 0, maxWidth: '463px' }}>
            <img
              alt="body"
              src={bodyImage}
              width="100%"
              style={{ maxHeight: '463px' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '60px 15px 30px' }}>
            <Typography
              style={{ color: '#885880', fontSize: '20px', marginBottom: '40px' }}
              paragraph
              variant="h6"
            >
              CBD helps activate the receptors so that the body produces its own cannabinoids,
              known as endocannabinoids (ECS).
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px', marginBottom: 0 }}>
              The ECS consists of two primary cannabinoid receptors:
              CB1 receptors (most concentrated in the central nervous system and brain)
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px', marginBottom: '40px' }}>
              CB2 receptors (abundant in the cardiovascular, gastrointestinal, and peripheral
              nervous systems)
            </Typography>
            <Typography paragraph style={{ fontSize: '20px', lineHeight: '27px' }}>
              The natural cannabinoids and phytonutrients found in cannabis communicate with
              these signal receptors of the ECS.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default CannabinoidsWork;
