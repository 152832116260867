import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import join from '../../assets/join.jpg';

const useStyles = makeStyles((theme) => ({
  joinTheWellCornerCommunityStyle: {
    fontSize: '40px',
    fontFamily: 'MyriadCondensed',
  },
  ulList: {
    margin: '0',
  },
  listDetailJoin: {
    fontSize: '25px',
    color: '#000000',
    marginBottom: '45px',
    fontFamily: 'MyriadCondensed',
  },
  imageStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '29%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 410,
      height: 118,
    },
  },
  content: {
    marginBottom: '200px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
}));

const JoinSectionNew = () => {
  const history = useHistory();

  const classes = useStyles();
  return (
    <Box width="100%" className={classes.content}>
      <Grid container>
        <Grid
          item
          sm={6}
          xs={12}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Box
            alignItems="flex-start"
            boxSizing="border-box"
            display="block"
            flexDirection="column"
            justifyContent="space-evenly"
            padding="40px"
            height="100%"
            style={{
              margin: '55px 0',
            }}
          >
            <Box style={{ color: '#EC8843' }}>
              <Typography
                style={{ fontFamily: 'Myriad' }}
                variant="h3"
                className={classes.joinTheWellCornerCommunityStyle}
              >
                Join the
              </Typography>
              <Typography
                variant="h3"
                className={classes.joinTheWellCornerCommunityStyle}
              >
                WELLCORNER COMMUNITY
              </Typography>
            </Box>
            <Grid container style={{ marginTop: '80px' }}>
              <Grid>
                <ul className={classes.ulList}>
                  <li className={classes.listDetailJoin}>
                    Track your wellness journey with surveys
                  </li>
                  <li className={classes.listDetailJoin}>Receive offers and discounts</li>
                  <li className={classes.listDetailJoin}>
                    Help others by providing feedback on products and their formulations
                  </li>
                  <li className={classes.listDetailJoin}>
                    Join a network of others who use our products
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: 'center' }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push('/register')}
                style={{
                  width: '150px',
                  height: '66px',
                  fontSize: '20px',
                  textTransform: 'inherit',
                  borderRadius: '15px',
                }}
              >
                Sign Up
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box
            height={768}
            overflow="hidden"
            width="100%"
            margin="auto"
          >
            <img
              style={{
                width: '100%',
                margin: '0',
                position: 'relative',
                textAlign: 'center',
                background: '#000',
              }}
              alt="join the community"
              src={join}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JoinSectionNew;
