import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Api from '../../common/Api';

import { states } from '../../constants';
import { titleCase } from '../../common/utils';

const ProfilePage = ({ onNotification }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('United States');
  const [zipCode, setZipCode] = useState('');

  useEffect(() => {
    Api.getProfile().then((profile) => {
      const { address } = profile;
      setFirstName(profile.first_name);
      setLastName(profile.last_name);
      setStreetAddress('address1' in address ? address.address1 : '');
      setCity('city' in address ? address.city : '');
      if ('province' in address) {
        const testProvinceText = titleCase(address.province);
        const testProvinceValue = address.province.toUpperCase();
        const value = states.filter((s) => (
          testProvinceText === s.text || testProvinceValue === s.value));
        if (value.length > 0) {
          setState(value[0].text);
        }
      }
      setCountry('United States');
      setZipCode('zip' in address ? address.zip : '');
    });
  }, []);

  return (
    <Box padding={4}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const profile = {
            first_name: firstName,
            last_name: lastName,
            address: {
              address1: streetAddress,
              city,
              province: state,
              country,
              zip: zipCode,
            },
          };
          Api.updateProfile(profile).then(() => {
            onNotification({
              message: 'Thank you for updating your profile',
              severity: 'success',
            });
          }).catch((error) => {
            const { data } = error.response;
            if ('errors' in data) {
              const key = Object.keys(data.errors)[0];
              const message = data.errors[key];
              onNotification({ message, severity: 'error' });
            } else {
              onNotification({ message: error.message, severity: 'error' });
            }
          });
        }}
      >
        <Card>
          <CardHeader
            title="Profile"
            subheader="Update your information and press SAVE."
            titleTypographyProps={{ color: 'primary' }}
            subheaderTypographyProps={{ color: 'secondary' }}
          />
          <CardContent>
            <Grid
              container
              spacing={4}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="First Name"
                  fullWidth
                  required
                  onChange={(event) => setFirstName(event.target.value)}
                  value={firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Last Name"
                  fullWidth
                  required
                  onChange={(event) => setLastName(event.target.value)}
                  value={lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Address"
                  fullWidth
                  required
                  onChange={(event) => setStreetAddress(event.target.value)}
                  value={streetAddress}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="City"
                  required
                  fullWidth
                  onChange={(event) => setCity(event.target.value)}
                  value={city}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl required fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    style={{ minWidth: '186px' }}
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                  >
                    <MenuItem value="">
                      <em>Choose State</em>
                    </MenuItem>

                    {
                        states.map((option) => (
                          <MenuItem value={option.text} key={option.value}>
                            {option.text}
                          </MenuItem>
                        ))
                      }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Country"
                  disabled
                  fullWidth
                  required
                  value={country}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Zip Code"
                  fullWidth
                  required
                  onChange={(event) => setZipCode(event.target.value)}
                  value={zipCode}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </Box>
  );
};

ProfilePage.propTypes = {
  onNotification: PropTypes.func.isRequired,
};

export default ProfilePage;
