import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../../common/Api';

const useStyles = makeStyles((theme) => ({
  paddingStyle: {
    padding: '4%',
    [theme.breakpoints.up('sm')]: {
      padding: '8%',
    },
    [theme.breakpoints.up('md')]: {
      padding: '10%',
    },
  },
}));

const Login = ({ onNotification }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box className={classes.paddingStyle}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12} sm={6}>
          <Card>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                Api.login({ email, password })
                  .then(() => {
                    onNotification({
                      message: 'Welcome back, you are now logged in',
                      severity: 'success',
                    });
                    history.push('/home');
                  })
                  .catch((error) => {
                    const { data } = error.response;
                    if ('errors' in data) {
                      const key = Object.keys(data.errors)[0];
                      const message = data.errors[key];
                      onNotification({ message, severity: 'error' });
                    } else {
                      onNotification({ message: error.message, severity: 'error' });
                    }
                  });
              }}
            >
              <CardHeader title="Login" />
              <CardContent>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  required
                  onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  required
                  onChange={(event) => setPassword(event.target.value)}
                />
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Login
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    Api.forgotPassword(email)
                      .then(() => {
                        const message = `We have sent an email to 
                      ${email}. Please 
                      check your inbox and click on the 
                      link to reset your password.`;
                        onNotification({
                          message,
                          severity: 'success',
                        });
                      });
                  }}
                  variant="contained"
                >
                  Forgot Password
                </Button>
                <Button
                  color="secondary"
                  onClick={() => history.push('/register')}
                  variant="contained"
                >
                  Sign Up
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

Login.propTypes = {
  onNotification: PropTypes.func.isRequired,
};

export default Login;
