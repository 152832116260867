import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Api from '../../common/Api';
import GaApi from '../../analytics/gaApi';

const Register = ({ practiceId, onNotification }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [birthday, setBirthday] = useState(null);
  const [hempProductSelections, setHempProductSelections] = useState(null);
  const [hempProductTypes, setHempProductTypes] = useState([]);
  const [referralId, setReferralId] = useState(null);
  const [referralTypes, setReferralTypes] = useState([]);

  const history = useHistory();
  useEffect(() => {
    Api.getHempProductTypes().then((values) => {
      const initialSelections = values.reduce((previous, current) => {
        const updated = { ...previous };
        updated[current.id] = false;
        return updated;
      }, { });
      setHempProductSelections(initialSelections);
      setHempProductTypes(values);
    });
  }, []);
  useEffect(() => {
    Api.getReferralTypes().then(setReferralTypes);
  }, []);
  return (
    <Box
      padding={4}
      alignItems="center"
    >
      <Grid
        alignItems="center"
        container
        justify="center"
        spacing={2}
        direction="column"
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography align="center" variant="h4" style={{ color: '#93638b' }}>
              Register here with the wellCORNER Community
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  • Track your wellness over time by completing a survey
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  • Receive offers and discounts
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  • Help others by providing feedback on products and product formulation
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  • Join a database of others who use hemp based products
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const hempTypes = Object.keys(hempProductSelections)
                .filter((key) => hempProductSelections[key]);
              const registerItems = {
                email,
                password,
                firstName,
                lastName,
                practiceId,
                hempTypes,
                referralId,
              };
              if (birthday) {
                registerItems.birthday = birthday.toISOString();
              }
              Api.register(registerItems)
                .then(() => {
                  onNotification({
                    message: 'Your registration has been successfully completed',
                    severity: 'success',
                  });
                  GaApi.sendEvent('REGISTER', 'New user registered');
                  history.push('/home');
                })
                .catch((error) => {
                  const { data } = error.response;
                  if ('errors' in data) {
                    const key = Object.keys(data.errors)[0];
                    const message = data.errors[key];
                    onNotification({ message, severity: 'error' });
                  } else {
                    onNotification({ message: error.message, severity: 'error' });
                  }
                });
            }}
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Card style={{ maxWidth: '1024px' }}>
                <CardHeader
                  title="Register"
                  subheader="Input your information and press register"
                  titleTypographyProps={{ color: 'primary' }}
                  subheaderTypographyProps={{ color: 'secondary' }}
                />
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        required
                        fullWidth
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Password"
                        type="password"
                        required
                        fullWidth
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        helperText="Eight characters, one number, distinct from username and common passwords"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        required
                        fullWidth
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        required
                        fullWidth
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/yyyy"
                        margin="normal"
                        fullWidth
                        label="Birthday"
                        value={birthday}
                        onChange={(date) => setBirthday(date)}
                        style={{ marginTop: 0 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl required>
                            <FormLabel>How did you hear about wellCORNER?</FormLabel>
                            <RadioGroup
                              value={referralId}
                              onChange={(event) => {
                                const id = parseInt(event.target.value, 10);
                                setReferralId(id);
                              }}
                            >
                              {
                                referralTypes.map(({ id, name }) => (
                                  <FormControlLabel
                                    key={id}
                                    value={id}
                                    control={<Radio required />}
                                    label={name}
                                  />
                                ))
                              }
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl>
                            <FormLabel color="primary">
                              Have you used hemp based products in the past?
                            </FormLabel>
                            <FormGroup>
                              {
                                hempProductTypes.map(({ id, name }) => (
                                  <FormControlLabel
                                    key={id}
                                    control={(
                                      <Checkbox
                                        checked={hempProductSelections[id]}
                                        onChange={(event) => {
                                          setHempProductSelections((previousSelections) => ({
                                            ...previousSelections,
                                            [id]: event.target.checked,
                                          }));
                                        }}
                                      />
                                    )}
                                    label={name}
                                  />
                                ))
                              }
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Register
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

Register.propTypes = {
  practiceId: PropTypes.number.isRequired,
  onNotification: PropTypes.func.isRequired,
};

export default Register;
