import ReactGA from 'react-ga';

class GaApi {
  constructor() {
    this.profileKey = sessionStorage.getItem('profileKey');
    this.slug = 'N/A';
    if (sessionStorage.getItem('practiceSlug') != null) {
      this.slug = sessionStorage.getItem('practiceSlug');
    }
  }

  setInfo() {
    /*
    in google analytics, profileKey is dimension1
    slug is dimension2
    */
    ReactGA.set({ dimension1: this.profileKey });
    ReactGA.set({ dimension2: this.slug });
  }

  pageView(pathname, practiceSlug = null) {
    if (practiceSlug !== null) {
      this.slug = practiceSlug;
    }
    this.setInfo();
    ReactGA.pageview(pathname);
  }

  startSurvey() {
    this.setInfo();
    // start survey, seconds should be 0
    /*
    in google analytics, startSurvey is metric1
    */
    ReactGA.set({ metric1: 0 });
  }

  endSurvey(endInSeconds) {
    this.setInfo();
    // end survey seconds
    /*
    in google analytics, endSurvey is metric2
    */
    ReactGA.set({ metric2: endInSeconds });
    ReactGA.timing({
      category: 'Submit Survey',
      variable: 'Survey Completion time in seconds',
      value: endInSeconds,
    });
  }

  sendEvent(category, action) {
    this.setInfo();
    /*
    Event format:
      category: 'CHECKOUT',
      action: 'Checkout button pressed',
      label: 'tcc',
    */
    ReactGA.event({
      category,
      action,
      label: this.slug,
    });
  }
}

export default new GaApi();
