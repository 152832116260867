import Badge from '@material-ui/core/Badge';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const CartWithBadge = ({
  cart,
}) => {
  const history = useHistory();
  const [cartSize, setCartSize] = useState(0);

  const useStyles = makeStyles(() => ({
    iconCart: {
      color: '#68923E;',
      margin: '0 20px 0 0',
      width: '20px',
      display: 'none',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const size = cart
      .map(({ quantity }) => quantity)
      .reduce((previous, current) => previous + current, 0);
    setCartSize(size);
  }, [cart]);

  return (
    <IconButton className={classes.iconCart} aria-label="cart" onClick={() => history.push('/cart')}>
      <StyledBadge badgeContent={cartSize} color="secondary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
};

CartWithBadge.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
};

export default CartWithBadge;
