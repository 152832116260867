import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Api from '../../common/Api';
import SymptomScale from './SymptomScale';
import SymptomResultsTable from './SymptomResultsTable';
import OtherResultsTable from './OtherResultsTable';

const SurveyResults = () => {
  const [severityScale, setSeverityScale] = useState([]);
  const [distressScale, setDistressScale] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [showKey, setShowKey] = useState(false);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  useEffect(() => {
    setShowKey(!isSmall);
  }, [isSmall]);
  useEffect(() => {
    Api.getSurvey().then((value) => {
      const severitySection = value.pages[1].questions[0];
      const distressSection = value.pages[2].questions[0];
      const key = Object.keys(severitySection)[0];
      const severityValues = severitySection[key][0].options
        .map((option) => option.option_text);
      const distressValues = distressSection[key][0].options
        .map((option) => option.option_text);
      setSeverityScale(severityValues);
      setDistressScale(distressValues);
    });
  }, []);
  useEffect(() => {
    Api.getSurveyAnswers(startDate, endDate).then(setAnswers);
  }, [startDate, endDate]);
  return answers === null ? null : (
    <Box padding={4}>
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography style={{ color: '#885880' }} variant="h4">
            My Wellness Survey History
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            The following scale is used to document symptom severity and distress:
          </Typography>
        </Grid>
        {
          showKey ? (
            <>
              <Grid item xs={12}>
                <SymptomScale label="Severity Scale" values={severityScale} />
              </Grid>
              <Grid item xs={12}>
                <SymptomScale label="Distress Scale" values={distressScale} />
              </Grid>
            </>
          ) : null
        }
        <Grid item>
          <Typography>
            This report shows all of your past responses, you can filter dates
            by completing the fields below.
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            spacing={1}
          >
            <Grid item style={{ width: '100%' }}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justify="space-between"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                  >
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/yyyy"
                        margin="normal"
                        fullWidth
                        label="Start Date"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        style={{ marginTop: 0 }}
                      />
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/yyyy"
                        margin="normal"
                        fullWidth
                        label="End Date"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        style={{ marginTop: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                  >
                    <Hidden smUp>
                      <Grid item>
                        <Button
                          color="primary"
                          className="no-print"
                          onClick={() => setShowKey((previous) => !previous)}
                          variant="contained"
                        >
                          Survey Key
                        </Button>
                      </Grid>
                    </Hidden>
                    <Grid item>
                      <Button
                        color="primary"
                        className="no-print"
                        onClick={() => window.print()}
                        variant="contained"
                      >
                        Print History
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              answers.map((answer, index) => (
                index === answers.length - 1 ? (
                  <Grid item key={answer.name} style={{ width: '100%' }}>
                    <OtherResultsTable answers={answer} />
                  </Grid>
                ) : (
                  <Grid item key={answer.name} style={{ width: '100%' }}>
                    <SymptomResultsTable answers={answer} />
                  </Grid>
                )
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyResults;
