import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

const OtherCard = ({
  answer, question, onAnswer,
}) => (
  <Card style={{ width: '100%' }}>
    <CardHeader
      title="COMPLEMENTARY AND ALTERNATIVE MEDICINE"
      titleTypographyProps={{ style: { color: '#93638b' } }}
    />
    <CardContent>
      <Grid
        alignItems="flex-start"
        container
        direction="column"
      >
        <Grid item>
          <Typography paragraph>
            Complementary and alternative medicine (CAM) is the term for products and practices
            that are meant to promote general wellness and are not part of standard medical
            care.  In other words, CAM products and practices are not physician
            prescribed.  What CAM products or practices have you used in the past promote
            your overall wellness?
          </Typography>
        </Grid>
        <Grid item>
          <FormGroup>
            {
              question.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  control={(
                    <Checkbox
                      checked={answer.some((id) => id === option.id)}
                      onChange={(event) => {
                        const { checked } = event.target;
                        const updatedAnswer = checked
                          ? answer.concat(option.id)
                          : answer.filter((id) => id !== option.id);
                        onAnswer(question.id, updatedAnswer);
                      }}
                    />
                  )}
                  label={option.option_text}
                  style={{
                    textAlign: 'left',
                  }}
                />
              ))
            }
          </FormGroup>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

OtherCard.propTypes = {
  answer: PropTypes.arrayOf(
    PropTypes.number,
  ),
  question: PropTypes.shape({
    id: PropTypes.number,
    options: PropTypes.arrayOf(
      PropTypes.shape({ }),
    ),
  }).isRequired,
  onAnswer: PropTypes.func.isRequired,
};

OtherCard.defaultProps = {
  answer: [],
};

export default OtherCard;
