import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import tinctureImg1 from '../assets/tincture_w_juice-1_FINAL.jpg';
import tinctureImg2 from '../assets/tincture_w_tea-1_FINAL.jpg';

const Recipes = () => {
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    mainHeader: {
      color: '#93638b',
    },
    secondaryHeader: {
      color: '#68923E',
    },
    link: {
      textDecoration: 'underline',
    },
    mainGrid: {
      padding: '0rem 2rem',
    },
    grey: {
      color: 'grey',
    },
    greyVerticalPadding: {
      color: 'grey',
      padding: '2rem 0rem',
    },
    leftPadding: {
      paddingLeft: '2rem',
    },
    img: {
      width: '100%',
    },

  }));

  const classes = useStyles();

  return (

    <Box padding={4}>
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography
              className={classes.mainHeader}
              align="center"
              variant="h4"
            >
              CBD Releaf Drop Recipes
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align="center"
              variant="h5"
              className={classes.secondaryHeader}
            >
              Do you prefer the CBD Releaf Drops, but want to change the flavor?
              Try these strategies developed by Maya Leiva, PharmD, APP,
              Advisory Board Member and Hematology/Oncology Advanced Practice Pharmacist.
              See:
              {' '}
              <Link href="#/about" className={classes.link}> About Us</Link>
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.mainGrid}
          >
            <Typography className={classes.greyVerticalPadding} variant="h4">
              Flavor Chaser
            </Typography>
            <Typography align="center">
              <img alt="Tincture" src={tinctureImg1} className={classes.img} />
            </Typography>
            <Typography className={classes.grey} style={{ paddingTop: '2rem' }} variant="h4">
              Take a small bite of the flavor chaser, follow with CBD,
              then chase with the remainder – Teaspoon or Tablespoon Based on Preference
            </Typography>
            <Grid
              className={classes.leftPadding}
            >
              <List>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp;Honey
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Chocolate syrup
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Peanut Butter
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Nutella/Hazelnut spread
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Jam or preserves
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Grenadine
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            style={{ padding: '0rem 2rem' }}
          >
            <Typography className={classes.greyVerticalPadding} variant="h4">
              Flavor Mixes
            </Typography>
            <Typography align="center">
              <img alt="Ticnture" src={tinctureImg2} className={classes.img} />
            </Typography>
            <Typography className={classes.greyVerticalPadding} variant="h4">
              Place CBD Releaf Drop Dose in 1-3 oz of Liquid
            </Typography>
            <Typography className={classes.grey} variant="h4">
              Fruit Juices or Smoothie
            </Typography>
            <Grid
              className={classes.leftPadding}
            >
              <List>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp;Apple
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Orange
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Grape
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Pineapple
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Guava
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Fruit Punch
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Typography className={classes.grey} variant="h4">
              Sparkling Juices or Sodas
            </Typography>
            <Grid
              className={classes.leftPadding}
            >
              <List>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp;Apple
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Orange
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Lemon or Lime
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Cola
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Root Beer
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    •&nbsp;&nbsp; Club soda
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid item>
            <Box align="center" color="#68923E">
              <Typography variant="h4" style={{ padding: '2rem' }}>
                Shop our CBD products
              </Typography>
            </Box>
            <Typography align="center">
              <Button
                onClick={() => {
                  history.push('/shop');
                }}
                align="center"
                variant="contained"
                color="primary"
              >
                Click Here
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Recipes;
