import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import schaedlerImage from '../assets/bios/schaedler.png';
import perezImage from '../assets/bios/perez.jpg';
import mowatImage from '../assets/bios/mowat.png';
import littonImage from '../assets/bios/litton.png';
import galloImage from '../assets/bios/gallo.png';
import leivaImage from '../assets/bios/leiva.png';

import BioModal from './BioModal';

const Bio = ({ onBioClick, person }) => (
  <Grid
    container
    direction="column"
    spacing={2}
    onClick={() => onBioClick(person)}
    style={{ cursor: 'pointer' }}
  >
    <Grid item>
      <Box
        style={{
          background: `url(${person.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        height={192}
        width={128}
      />
    </Grid>
    <Grid
      style={{ width: '128px' }}
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Typography align="center" variant="caption" style={{ fontWeight: 'bold' }}>{person.name}</Typography>
      <Typography align="center" variant="caption">{person.title}</Typography>
    </Grid>
  </Grid>
);

Bio.propTypes = {
  person: PropTypes.instanceOf(Object).isRequired,
  onBioClick: PropTypes.func.isRequired,
};

const AboutPage = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [bioImage, setBioImage] = useState(schaedlerImage);
  const [description, setDescription] = useState([]);

  const people = [
    {
      name: 'Tom A. Gallo, MS, MDA',
      image: galloImage,
      title: 'Virginia Cancer Institute',
      description: [`Thomas A. Gallo, MS, MDA, is executive director of the Virginia Cancer Institute in
Richmond, Virginia, where he manages all aspects of a 24-provider medical oncology
practice with 7 medical offices, ambulatory infusion centers, laboratories, and more than
200 employees.`, `Mr. Gallo is a member of the Board of Directors of MD Value Care, an ACO of
independent physician practices; past president of the Executive Committee of the
MGMA Administrators in Oncology-Hematology Assembly (AOHA); a current member
and former president of the Board of Directors of the Virginia Association of
Hematologists and Oncologists (VAHO); and a former board member of the Community
Oncology Alliance. Mr. Gallo is an active and long-time member of the Association of
Community Cancer Centers, Mr. Gallo was ACCC President-Elect (2017-2018) and was
named 2018-2019 President of the Association of Community Cancer Centers (ACCC). He
currently serves on the ACCC Governmental Affairs Committee and has previously served
on the ACCC Board of Trustees (2004-2005).
`, `He received a BA in Economics from the College of William and Mary in Williamsburg,
Virginia, and an MS in Finance and a Master of Decision Analytics (MDA) degree from
Virginia Commonwealth University, where he was a Dean’s Scholar.`],
    },
    {
      name: 'Maya Leiva, PharmD, APP',
      image: leivaImage,
      title: 'Hematology/Oncology Advanced Practice Pharmacist',
      description: [`Maya Leiva, PharmD is an experienced Hematology and Oncology Advanced
Practice Pharmacist and educator. She received her pharmacy degree with
honors at Loma Linda University School of Pharmacy in 2010. Prior to
pharmacy school, she completed her B.S. in Microbiology and was an NIH
Fellow in the RISE program while pursuing her M.S. in Microbiology.
`, `
In addition to practicing in several states, she has had multiple faculty
appointments at pharmacy schools across the U.S. She serves as a preceptor
to pharmacy students and residents. She has subspecialties in supportive
care, pain management, immuno-oncology, and clinical informatics.
`, `
She is involved in her community as a volunteer member of the Medical
Reserve Corps for the Department of Health and Human Services and has
additional training in disaster preparedness. She is affiliated with Inova
Schar Cancer Institute in Falls Church, VA.`],
    },
    {
      name: 'Greg Litton, MD',
      image: littonImage,
      title: 'Medical Oncologist',
      description: [`Gregory Litton MD, is a medical oncologist. Dr Litton earned his medical
degree from the University of Washington School of Medicine in Seattle in
1983.`, `He served an internship and residency in Internal Medicine at the University of
Wisconsin in Madison. Dr. Litton trained in research at the University of
Wisconsin Clinical Cancer Center. He completed his clinical training with a
fellowship in Hematology/Oncology at the University of Utah School of
Medicine.
`, `He is board certified in Internal Medicine and Medical Oncology. Dr. Litton has
a specific clinical interest in the treatment of breast cancer and sarcomas.`],
    },
    {
      name: 'Rex Mowat, MD',
      image: mowatImage,
      title: 'Hematologist and Medical Oncologist',
      description: [`Rex Mowat, MD is a board-certified hematologist and medical 
        oncologist with the Toledo Clinic and is part of the cancer care team at the Maurer 
        Family Cancer Care Center. Dr Mowat is an expert in Blood Cancers and benign 
        conditions involving the blood and Lung cancer.`,
      `He received his medical degree from Wayne State University School of
Medicine and his internship and residency at William Beaumont
Hospital. He has been the Chief of Staff at Bixby Medical Center and
was the medical director at Hickman Cancer Center. Dr Mowat been
practicing medicine for over 30 years.`],

    },
    {
      name: 'Edith A. Perez, MD',
      image: perezImage,
      title: 'Hematologist and Medical Oncologist',
      description: [`Edith A. Perez, M.D., is a world-renowned American translational researcher and
        cancer specialist, known equally for her impact in designing innovative clinical trials
        and for her patient-centered humanitarian passion.`, `
        Dr. Perez earned her medical degree from the University of Puerto Rico School of
        Medicine in San Juan and completed her residency in internal medicine at the Loma
        Linda University Medical Center in California. In addition, she completed her
        fellowship training at the University of California, Davis. She received additional
        leadership training from the Wharton School of the University of Pennsylvania, in
        Philadelphia and the Harvard Executive Development Program at the Harvard
        Kennedy School, in Boston. Dr. Perez is board certiﬁed in internal medicine, medical
        oncology and hematology.`,
      `Dr. Perez joined Bolt Biotherapeutics in 2020 as its Chief Medical Oﬃcer. She also
        maintains her academic affiliation as Professor of Medicine with Mayo Clinic and
        holds leadership roles in various non-profit organizations, including The Donna
        Foundation and Stand Up to Cancer.`],
    },

    {
      name: 'Joel Schaedler, CEO',
      title: 'wellCORNER',
      image: schaedlerImage,
      description: [`Joel Schaedler is a seasoned healthcare executive with over 
        twenty-four years of experience within the pharmaceutical, biotech, and 
        specialty therapeutic arenas. His professional experience has been within 
        the public and private sectors. He has worked in various executive leadership 
        roles with three pharmaceutical and biotech organizations and was integral 
        in the launch of seven FDA approved products. On the private side, he has 
        been an active leader and principle of six, private specialty therapeutic 
        organizations and has extensive experience with corporate entities within 
        the cannabis arena.  Outside of healthcare, Mr. Schaedler is a board member and 
        active investor with four start-up enterprises within the commodities industry.`,
      `Mr. Schaedler is the founder and currently serves as Chief Executive Officer for 
      Cornerstone Specialty Network, LLC.  Cornerstone Specialty Network provides long-term, 
      sustainable value through an aggregated network of Community Oncology practices. 
      Founded in 2016, Cornerstone Specialty Network’s mission is to provide value to Community 
      Oncology practices in order to help maximize the quality of patient care.`],

    },
  ];
  const onBioClick = (person) => {
    setName(person.name);
    setTitle(person.title);
    setDescription(person.description);
    setBioImage(person.image);
    setModalOpen(true);
  };
  return (
    <Box padding={4}>
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={4}
      >
        <Grid item>
          <Typography
            align="center"
            style={{ color: '#885880' }}
            variant="h4"
          >
            About wellCORNER
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
          >
            <Grid item sm={4} xs={12}>
              <Typography
                color="primary"
                variant="h4"
                align="center"
              >
                Who is wellCORNER?
                <BioModal
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  name={name}
                  title={title}
                  description={description}
                  image={bioImage}
                />
              </Typography>
              <Typography paragraph>
                wellCORNER’s mission is to provide patients access to scientifically
                reviewed information about hemp based products,
                as well as access to high-quality hemp products.
              </Typography>
              <Typography paragraph>
                We partner with oncology healthcare professionals to develop products and provide
                patient education specifically for oncology patients.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{ color: '#885880' }}
                variant="h4"
                align="center"
              >
                Our Brand Values
              </Typography>
              <Typography paragraph>
                1. We only offer products that are tested for quality and purity.
              </Typography>
              <Typography paragraph>
                2. We recommend what we love and what we think is worthy of your time and money
              </Typography>
              <Typography paragraph>
                3. We value your trust and confidence above all things
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            color="primary"
            variant="h4"
          >
            Professional Advisory Board
          </Typography>

        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            spacing={2}
            justify="center"
          >

            {people.map((person) => (
              <Grid item key={person.name}>
                <Bio person={person} onBioClick={onBioClick} />
              </Grid>
            ))}

          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box bgcolor="primary.main" padding={2}>
            <Typography align="center" color="textSecondary" variant="h4">
              wellCORNER and its Professional Advisory Board has partnered
              exclusively with Papa &amp; Barkley to offer hemp based
              products to patients.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ background: '#b1d3d2', marginTop: 60 }}>
          <Typography variant="h4">
            All Papa &amp; Barkley products:
          </Typography>
          <List>
            <ListItem>
              <Typography>
                •&nbsp;&nbsp;Contain clean ingredients
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                •&nbsp;&nbsp;Are whole plant infused extracts
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                •&nbsp;&nbsp;Triple-tested by 3rd-party laboratories
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                •&nbsp;&nbsp;Made with hemp using organic and regenerative farming products
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                •&nbsp;&nbsp;Are solventless extracts, derived with
                no chemicals or harsh extraction methods
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={8} md={6} style={{ paddingTop: 60 }}>
          <Typography
            paragraph
            color="primary"
            align="center"
          >
            It is always important to inform your physician of any complementary and alternative
            medicine you are taking. The wellCORNER Professional Advisory Board
            reviews but does not specifically recommend hemp products.
            All hemp use should be
            discussed with your individual doctor.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutPage;
