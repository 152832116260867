import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cbdBottle from '../assets/cbd_bottle_table.jpg';
import coverTwo from '../assets/hcp_with_patient.png';

const sendEmail = () => {
  window.open('mailto:info@wellcorner.com?subject=I am interested in wellCORNER for my practice');
};

const HealthProfessionals = () => {
  const isComputerScreenSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    grad: {
      backgroundImage: 'linear-gradient(#346B81, white)',
    },
    grey: { color: 'grey' },
    topPadding: { padding: '2rem 0rem' },
    greyBox: {
      borderColor: 'grey',
      borderWidth: '1px',
      borderStyle: 'solid',
      minHeight: '8rem',
    },
    purpleBox: {
      borderColor: 'purple',
      borderWidth: '1px',
      borderStyle: 'solid',
      minHeight: '8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    greenBox: {
      borderColor: '#68923E',
      borderWidth: '1px',
      borderStyle: 'solid',
      minHeight: '8rem',
    },
    heroImage: {
      maxWidth: isComputerScreenSize ? '90%' : '40rem',
      width: 'auto',
      height: 'auto',
    },
    secondImage: {
      maxWidth: isComputerScreenSize ? '90%' : '40rem',
      width: 'auto',
      height: 'auto',
    },
    root: {
      padding: 0,
    },
    mainHeader: {
      color: '#93638b',
      padding: '2rem',
    },
    secondaryHeader: {
      color: 'white',
      padding: '5rem',
    },
    purpleColor: {
      color: '#93638b',
    },
  }));

  const history = useHistory();
  const classes = useStyles();

  return (
    <Box>
      <Container className={classes.root} maxWidth={false}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <Typography
              className={classes.mainHeader}
              align="center"
              variant="h4"
            >
              Expanding Patient Services
            </Typography>
          </Grid>
          <Grid item md={8} lg={8}>
            <Typography
              align="center"
              variant="h5"
              className={classes.grey}
              style={{ paddingBottom: '1rem' }}
            >
              wellCORNER&apos;s mission is to provide HCP reviewed patient
              education and access to tested,
              high quality products that help promote wellness for patients.
            </Typography>
          </Grid>
          <Grid item md={6} lg={6} sm={10} xs={10} className={classes.greyBox}>
            <Typography
              align="center"
              className={classes.grey}
              style={{ padding: '1rem 0rem' }}

            >
              If you would like to learn more about wellCORNER for your practice plaease
              request more information by click below, or emailing info@wellcorner.com
            </Typography>
            <Typography align="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  sendEmail();
                }}
              >
                Request more information
              </Button>
            </Typography>

          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ padding: '2rem 2.5rem' }}
          >
            <Box className={classes.purpleBox}>
              <Grid item md={8} lg={8} sm={10}>
                <Typography align="center" paragraph className={classes.purpleColor} variant="h6">
                  If you already offer wellCORNER.com in your practice and have a
                  request or need more information, visit
                </Typography>
                <Typography align="center" variant="h6">
                  <a
                    target="_blank"
                    href="https://www.CornerstoneOncology.com/wellcorner/"
                    rel="noreferrer"
                  >
                    www.CornerstoneOncology.com/wellcorner
                  </a>
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.grad} paddingBottom="2rem">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"

          >
            <Grid item>
              <Typography
                className={classes.secondaryHeader}
                align="center"
                variant="h4"
              >
                Benefits of offering wellCORNER.com to patients in your practice:
              </Typography>
            </Grid>
            <Grid item>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={coverTwo} alt="HCP with Patient" className={classes.heroImage} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item md={6} lg={5} sm={10} xs={10}>
            <List>
              <ListItem>
                <Typography style={{ paddingBottom: '2rem' }}>
                  •&nbsp;&nbsp;Save time on patient education: patients receive HCP
                  reviewed information about cannabis and hemp- based products at point of care
                </Typography>
              </ListItem>
            </List>
            <Typography paragraph>
              Access High Quality Products: refer patients to a
              line of high-quality hemp-based products
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Triple tested by a third-party
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; Federally legal, products are compliant with the US Farm Bill
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;All patients receive a 10% discount off MSRP through wellCORNER.com
                </Typography>
              </ListItem>
            </List>

            <Typography paragraph>
              Data & Technology drive the model’s success
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Collect and receive patient survey data related to perceptions
                  on product performance
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; Secure, HIPAA compliant, real-time order entry for patients
                </Typography>
              </ListItem>
            </List>

            <Typography paragraph>
              Streamlined Logistics for the practice
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Products shipped direct to patient, practice does not house inventory
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp; On-demand clinical training program about
                  cannabis and CBD products for HCPs
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Marketing support is offered to wellCORNER practices
                  including printed materials, and sampling
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Quarterly rebate available to practice or physician
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  •&nbsp;&nbsp;Know first about new products and services
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={8} lg={8} sm={10} xs={10} className={classes.topPadding}>
            <Typography align="center">
              <Button
                onClick={() => {
                  sendEmail();
                }}
                align="center"
                variant="contained"
                color="primary"
              >
                Request more information
              </Button>
            </Typography>
            <Typography align="center" className={classes.grey}>
              or email: info@wellcorner.com
            </Typography>
          </Grid>
          <Grid item md={8} lg={8}>
            <Typography align="center">
              <img alt="CBD Bottle" src={cbdBottle} className={classes.secondImage} />
            </Typography>
          </Grid>
          <Grid item md={8} lg={7} sm={10} xs={10}>
            <Typography style={{ color: 'grey' }} className={classes.topPadding} variant="h5">
              wellCORNER offers a line of naturally derived,
              hemp-based products produced by Papa & Barkley
            </Typography>

          </Grid>
          <Grid item md={8} lg={8} sm={10} xs={10}>
            <Box className={classes.greenBox}>
              <Box padding={2}>
                <Typography paragraph>
                  All Papa & Barkley products are:
                </Typography>
                <Grid item xs={12} sm={9}>
                  <List>
                    <ListItem>
                      <Typography>
                        •&nbsp;&nbsp;Contain clean ingredients
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        •&nbsp;&nbsp;Are whole plant infused extracts
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        •&nbsp;&nbsp;Triple-tested by 3rd-party laboratories
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        •&nbsp;&nbsp;Made with hemp using organic and regenerative farming products
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        •&nbsp;&nbsp;Are solventless extracts, derived with no chemicals
                        or harsh extraction methods
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Box>
            </Box>
            <Grid item>
              <Box align="center" color="#68923E">

                <Typography variant="h4" style={{ padding: '2rem' }}>
                  Shop our CBD products
                </Typography>
              </Box>
              <Typography align="center">
                <Button
                  onClick={() => {
                    history.push('/shop');
                  }}
                  align="center"
                  variant="contained"
                  color="primary"
                >
                  Click Here
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HealthProfessionals;
