import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cannabisFamilyImage from '../../assets/cannabis_family.png';

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '84%',
      paddingTop: '1%',
      paddingBottom: '1%',
    },
  },
  label: {
    textAlign: 'center',
    border: '1px solid #FFFFFF',
    fontSize: '25px',
    textTransform: 'uppercase',
    borderLeft: 'none',
    padding: '2px',
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #ffffff',
      marginTop: '25px',
    },
  },
  content: {
    marginTop: '11px',
    padding: '8px',
    border: '1px solid #ffffff',
    borderLeft: 'none',
    minHeight: '403px',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #ffffff',
    },
  },
  contentButton: {
    display: 'flex',
    maxWidth: '600px',
    margin: '150px auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  button: {
    width: '250px',
    height: '66px',
    fontSize: '20px',
    textTransform: 'inherit',
    borderRadius: '10px',
    margin: '0 50px 0 0',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
}));

const HempComparisonSectionNew = ({ learnMoreAboutHempRef }) => {
  const classes = useStyles();
  return (
    <Box
      id="learnMoreAboutHemp"
      style={{ maxWidth: '800px', margin: '50px auto 150px' }}
    >
      <Accordion
        style={{
          boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
          style={{ background: '#68923e', color: '#ffffff' }}
          ref={learnMoreAboutHempRef}
        >
          <Typography variant="h4" style={{ fontSize: '25px' }}>
            Expand to learn more about Hemp vs. Marijuana
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ background: '#68923e', color: '#ffffff' }}>
          <Grid
            container
            style={{
              marginTop: '22px',
            }}
          >
            <Grid item md={6} sm={12}>
              <Grid
                container
                direction="column"
              >
                <Typography
                  variant="h4"
                  style={{
                    textAlign: 'center',
                    border: '1px solid #FFFFFF',
                    fontSize: '25px',
                    textTransform: 'uppercase',
                    padding: '2px',
                    lineHeight: '30px',
                  }}
                >
                  Hemp
                </Typography>
                <Grid
                  style={{
                    marginTop: '11px',
                    padding: '8px',
                    border: '1px solid #ffffff',
                    minHeight: '403px',
                  }}
                >
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Naturally contains higher ratios of cannabinoids (like CBD and CBG) and terpenes
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    CBD supports the receptors so that the body makes its own cannabinoids,
                    known as endocannabinoids. Endocannabinoids help promote the proper
                    function of multiple organs and tissues of the body
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Contains less than 0.3% THC and is non-psychologically active
                    (won’t make you feel “high”)
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Legal under the 2018 US Farm Bill with specific labeling and marketing
                    requirements
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid
                container
                direction="column"
              >
                <Typography
                  variant="h4"
                  className={classes.label}
                >
                  Marijuana
                </Typography>
                <Grid
                  className={classes.content}
                >
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Naturally contains high concentrations of THC
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    THC can cause a psychological effect that creates a mind-altering “high” when a
                    person smokes it or uses it in cooking
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Contains lower ratios of naturally occurring cannabinoids (like CBD and CBG) and
                    terpenes
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Legal in some states for recreational and medicinal purposes
                  </Typography>
                  <Typography paragraph style={{ marginBottom: '20px' }}>
                    Not federally legal
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                style={{ padding: '23px 0 12px' }}
              >
                <img
                  alt="the cannabis family"
                  src={cannabisFamilyImage}
                  width="100%"
                  className={classes.imageStyle}
                />

              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

HempComparisonSectionNew.propTypes = {
  learnMoreAboutHempRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default HempComparisonSectionNew;
