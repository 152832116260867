import { useLocation } from 'react-router-dom';
import { nonPracticePaths } from '../constants';

export const getErrorMessage = (rawError) => {
  if (Object.keys(rawError).includes('non_field_errors')) {
    return rawError.non_field_errors;
  }
  return Object.keys(rawError).map(((field) => `${field}: ${rawError[field]}`));
};

export const getPracticeSlug = () => {
  const location = useLocation();
  const { pathname } = location;
  const firstPath = pathname.split('/')[1];
  return firstPath && !nonPracticePaths.includes(firstPath) ? `/${firstPath}` : '';
};

export const reformatDate = (timestamp) => {
  const event = new Date(timestamp);
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  };
  return event.toLocaleTimeString('en-US', options);
};

export const titleCase = (val) => {
  /* will return south dakota to South Dakota for states check */
  const valEdit = val;
  const returnArray = [];
  valEdit.toLowerCase().split(' ').forEach(
    (item, index) => {
      returnArray[index] = item.charAt(0).toUpperCase() + item.slice(1);
    },
  );
  return returnArray.join(' ');
};
