import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Carousel } from 'react-responsive-carousel';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import coverOne from '../../assets/welcomeSectionCovers/coverOne.jpg';
import coverTwo from '../../assets/welcomeSectionCovers/coverTwo.jpg';
import logoColor from '../../assets/wellCornerLogos/logoBlackNew.png';

const useStyles = makeStyles((theme) => ({
  contentRight: {
    position: 'absolute',
    width: '41%',
    top: '50%',
    right: '43px',
    transform: 'translate(0, -50%)',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 14px 0 rgba(0,0,0,0.3)',
    maxWidth: '100%',

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 'auto',
      right: 'auto',
      width: '100%',
    },
  },
  boxContent: {
    padding: '85px 70px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  button: {
    width: '164px',
    height: '66px',
    fontSize: '20px',
    textTransform: 'inherit',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '140px',
    },
  },
  welcome: {
    position: 'relative',
    marginTop: '60px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
    },
  },
  img: {
    borderRadius: '15px',
  },
  '@global': {
    '.carousel .slider-wrapper.axis-horizontal .slider .slide': {
      background: 'none',
    },
  },
}));

const WelcomeSectionNew = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        bgcolor="default"
        overflow="hidden"
        width="100%"
      >
        <Grid
          container
          bgcolor="default"
          className={classes.welcome}
        >
          <Grid item sm={9} xs={12} style={{ maxHeight: 680 }}>
            <Carousel
              autoPlay
              infiniteLoop
              axis="horizontal"
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={false}
            >
              <img className={classes.img} alt="Welcome to wellCORNER" src={coverOne} />
              <img className={classes.img} alt="Welcome to wellCORNER" src={coverTwo} />
            </Carousel>
          </Grid>

          <Grid
            item
            sm={3}
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.contentRight}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.boxContent}
            >

              <Grid
                item
                container
                direction="row"
                alignItems="flex-start"
                xs={12}
                sm={12}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: '#885880',
                    fontSize: '40px',
                    textAlign: 'left',
                    width: '100%',
                    textTransform: 'uppercase',
                  }}
                >
                  Welcome to
                </Typography>
                <Grid
                  item
                  container
                  direction="column"
                  style={{
                    width: '100%',
                    height: 'auto',
                    paddingTop: '5%',
                    paddingBottom: '5%',
                    marginLeft: '-10px',
                  }}
                  xs={12}
                >
                  <img
                    alt="wellCORNER"
                    src={logoColor}
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </Grid>
                <Typography
                  variant="h6"
                  style={{
                    color: '#565656',
                    fontFamily: 'MyriadCondensed',
                    fontSize: '25px',
                    paddingTop: '30',
                  }}
                >
                  It’s your journey. We are here to help.
                </Typography>
                <Grid style={{ display: 'flex', marginTop: '50px' }}>
                  <Grid style={{ marginRight: '40px' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      href={window.location.hostname === 'survey.wellcorner.com'
                        ? 'https://www.wellcorner.com/categories.html'
                        : 'https://dev.wellcorner.com/categories.html'}
                      className={classes.button}
                    >
                      SHOP
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      color="secondary"
                      variant="contained"
                      href={window.location.hostname === 'survey.wellcorner.com'
                        ? 'https://www.wellcorner.com/about'
                        : 'https://dev.wellcorner.com/about'}
                      className={classes.button}
                    >
                      About Us
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WelcomeSectionNew;
