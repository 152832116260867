import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const ProductSection = forwardRef(({ products }, ref) => {
  const history = useHistory();
  return (
    <Box ref={ref} padding={4}>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography
            align="center"
            color="secondary"
            variant="h4"
          >
            Shop Products
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            spacing={4}
            justify="center"
          >
            {
              products.map((product) => (
                <Grid
                  item
                  key={product.id}
                  onClick={() => history.push(`/product/${product.handle}`)}
                >
                  <Grid
                    container
                    direction="column"
                  >
                    <Grid item>
                      <img alt={product.title} src={product.images[0].src} width={256} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">{product.title}</Typography>
                    </Grid>
                    <Grid item>
                      { product.availableForSale ? (
                        <>
                          <Typography display="inline">
                            Starting at:&nbsp;
                          </Typography>
                          <Typography display="inline" style={{ color: '#D31C5C', fontWeight: 'bold' }}>
                            $
                            {product.variants[0].price}
                          </Typography>
                        </>
                      ) : (
                        <Typography>
                          Unavailable
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

ProductSection.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({ }),
  ).isRequired,
};

export default ProductSection;
