import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const ItemQuantity = ({
  quantity,
  onAdd,
  onSubtract,
  displayQuantityLabel,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [color, setColor] = useState('#767676');
  return (
    <Grid item>
      {
        displayQuantityLabel
          ? (
            <Typography
              style={{
                color: '#565656',
                padding: '0',
                fontSize: '20px',
                marginBottom: '10px',
              }}
            >
              Quantity
            </Typography>
          )
          : null
      }

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={0}
        style={{ border: '.8px solid #565656', width: 117, height: 42 }}
        onMouseEnter={() => setColor('#68923E')}
        onMouseLeave={() => setColor('#767676')}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="center"
          xs={4}
        >
          <Button
            color="primary"
            style={{ fontSize: '20px', marginTop: '-3px', color: '#68923E' }}
            onClick={() => onSubtract()}
          >
            -
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Typography align="center" style={{ fontSize: '20px', color: '#000000' }}>{quantity}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Button
            color="primary"
            style={{ fontSize: '20px', marginTop: '-3px', color: '#68923E' }}
            onClick={() => onAdd()}
          >
            +
          </Button>
        </Grid>

      </Grid>
    </Grid>
  );
};

ItemQuantity.propTypes = {
  displayQuantityLabel: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default ItemQuantity;
