import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import pbFallImage from '../../assets/pb-fall.jpeg';
import capsulesImage from '../../assets/capsules.jpeg';

const ProductDifferenceSection = () => (
  <Box bgcolor="#e3e3e3" padding={4}>
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <Typography
          color="primary"
          variant="h4"
        >
          What makes Papa &amp; Barkley Products Different?
        </Typography>
        <Typography paragraph>
          Papa &amp; Barkley uses a patented and completely chemical free lipid infusion
          process to cleanly produce highly concentrated CBD oil. This unique approach
          maintains all of the plant’s original terpenes, phytocannabinoids, and phytonutrients.
        </Typography>
        <Typography paragraph>
          Most other companies use harsh extraction methods such as CO2 supercritical
          extraction, which can strip the plant of essential and naturally occurring
          terpenes and certain Phytocannabinoids yielding an incomplete CBD product.
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <img alt="fall" src={pbFallImage} width="100%" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <img alt="capsules" src={capsulesImage} width="100%" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography
          color="primary"
          variant="h4"
        >
          How are products tested?
        </Typography>
        <Typography paragraph>
          Each Papa &amp; Barkley CBD product is tested four times throughout the production
          process. Using a 3rd party laboratory, products are tested for potency, microbial
          contamination, and pesticides to ensure purity. If at any point in our internal
          testing, pesticides or microbial contamination is detected, the batch is destroyed.
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default ProductDifferenceSection;
