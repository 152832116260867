import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Link from '@material-ui/core/Link';

const FAQPage = () => {
  const questions = [
    {
      question: `What’s the most important 
        thing cancer patients should know about hemp based products?`,
      answerByParagraph: [
        <Typography paragraph>
          There’s still a lot to learn. So always let your
          health care team know if you are using a hemp based product.
          That way, you can make sure nothing interacts adversely with
          your cancer treatments or other medications. Also, avoid
          products with health-related claims on their labels.
          To date, FDA has not approved the use of CBD products
          to treat any medical conditions other than one
          prescription drug approved to treat two rare,
          severe pediatric epilepsy disorders.
          To review hemp based products that wellCORNER and its Professional
          Advisory Board has selected,
          {' '}
          <Link href="#/shop">
            click here
          </Link>
          .
        </Typography>],

    },
    {
      question: 'What are the dangers of using hemp based products?',
      answerByParagraph: [
        <Typography paragraph>
          Quality, purity, and regulation are the biggest concerns.
          All drugs, dietary supplements and foods are regulated by the
          U.S. Food and Drug Administration (FDA). The 2018 Farm Bill
          legalized the cultivation of hemp containing 0.3% or less THC.
          The regulations implementing the Farm Bill contain stringent
          testing requirements. Despite these requirements, many hemp
          based products are sold without undergoing the required
          testing.
          That means it’s hard to know how much CBD or THC
          is really in many products.
        </Typography>,
        <Typography paragraph>
          Quality: Certain hemp based products have been found to contain significantly
          less CBD or more THC than advertised.
        </Typography>,
        <Typography paragraph>
          Purity: THC and CBD are both extracted from hemp and marijuana using similar processes.
          But trace amounts of THC could still end up in hemp based products.
          If the THC is at a high enough concentration, it
          could produce the psychologically active effects or “high” that THC is known for.
        </Typography>,
        <Typography paragraph>
          Second, if a company produces both CBD and THC products
          in the same facility, there can be cross-contamination —
          whether it’s during the extraction, handling or packaging of the product.
        </Typography>,
        <Typography paragraph>
          Third, the plant itself may have higher levels of THC than expected.
          This could be due to its environment, cross-contamination and
          pollination between male and female plants, which may result
          in offspring with higher THC content. Cross-contamination and
          pollination especially affect hemp plants, which must have less
          than or equal to 0.3% THC levels to be compliant.
        </Typography>,
        <Typography paragraph>
          It is important to use a product that has high, third-party testing
          standards. There have been some reports of people getting
          infections after using CBD and cannabis products. This is especially
          concerning for individuals who have problems with their immune system
          (immunocompromised) and are already susceptible to bacterial and fungal
          infections. It is important to use a product that tests not only for
          purity, but also other contaminants such as metals, like lead and
          arsenic, pesticides, and molds.
        </Typography>,
        <Typography paragraph>
          Papa & Barkley, the manufacturer of the wellCORNER’s hemp based
          products conducts in-house and 3rd party testing to verify the
          products’ quality and purity. Every product is triple-tested at
          key points for 66 pesticides, 5 microbials, and 10 residual
          solvents.
          The products are tested at the input cannabis, after
          it is blended,
          and as the packaged product.
        </Typography>,
        <Typography paragraph>
          To review hemp based products that wellCORNER and its Professional
          Advisory Board has selected,
          {' '}
          <Link href="#/shop">
            click here
          </Link>
          .
        </Typography>,
      ],
    },
    {
      question: 'Where does CBD come from?',
      answerByParagraph: [
        <Typography paragraph>
          Over the years,
          marijuana farmers have selectively
          bred their plants to contain high levels of
          THC and other compounds
          that suited their interests.
          However, hemp
          farmers rarely modify the plant.
          CBD comes from these compliant hemp plants.
        </Typography>],
    },
    {
      question: 'Do hemp based products have any side effects?',
      answerByParagraph: [
        <Typography paragraph>
          Specifically, CBD isolate oil
          can cause damage to the liver.
          In fact, this is on the warning label
          for Epidiolex®. In lab studies,
          CBD has been shown to block certain
          enzymes responsible for the breakdown
          or metabolism of drugs, such as
          CYP2D6 and CYP3A4. When these enzymes are
          blocked it can affect how
          drugs work and affect our bodies,
          either by making them less effective or
          more dangerous. This includes
          chemotherapy and other medications.
          There may be additional side effects.
          The government and other
          entities have been and are
          conducting studies to assess whether
          there are additional risks.
        </Typography>,
        <Typography paragraph>
          It’s very important to tell your doctor if you are using hemp
          based products. Your doctor needs to know about anything
          you’re taking that could interfere with your other
          medications to assess any
          potential risks that might be associated with your use of a hemp based product.
        </Typography>,
        <Typography paragraph>
          To review hemp based products that wellCORNER and its Professional
          Advisory Board has selected,
          {' '}
          <Link href="#/shop">
            click here
          </Link>
          .
        </Typography>],
    },
    {
      question: `Will using the products wellCORNER 
        offers come up on a drug test?`,
      answerByParagraph: [
        <Typography paragraph>
          Generally, CBD products that have been made
          with less than 0.3% THC, will not cause false
          positives on tests used to detect marijuana use
          (THC). If a product is contaminated with higher
          than 0.3% of THC or depending on the sensitivity
          of the drug test for THC levels, then it is
          possible to test positive on a drug screen
          for marijuana. We advise that you
          disclose use of all dietary supplements
          to the testing agent and consult your
          employer’s handbook on its policy for
          cannabis use. Reputable sources of CBD products,
          like wellCORNER, will have these products tested by an
          independent (3rd party) laboratory and have a certificate of
          analysis (COA) confirming the purity and concentration of
          ingredients including the amount of THC in the product.
        </Typography>],
    },
    {
      question: 'Can I overdose on CBD?',
      answerByParagraph: [
        <Typography paragraph>
          While it is unlikely to
          overdose on CBD, it is important to follow
          instructions for
          use of any product and keep products out of reach
          of children or pets.
        </Typography>],
    },
    {
      question: 'Ordering: How are products shipped?',
      answerByParagraph: [
        <Typography paragraph>
          Orders under $75 will be shipped by via
          USPS ground service. Rates may vary by
          shipping location. Orders over $75 qualify
          for free ground shipping.
        </Typography>],
    },
    {
      question: 'Ordering: What are your refund policies?',
      answerByParagraph: [
        <Typography paragraph>
          There is a 30-day satisfaction
          guarantee.
          The product manufacturer, Papa & Barkley stands behind its
          products with our 30-day product satisfaction guarantee.
          If you are not satisfied with the product, you may request
          a refund of the full purchase price within thirty (30)
          days of the product received date. No refunds will be given
          for products beyond thirty (30) days. No refunds
          will be issued for products without proof of purchase.
          Refunds given only for regular priced items, sale items
          are final sale and excluded from our return policy.
          If the return is a result of our error, such as an incorrect or
          defective product, you will receive a full refund for the item.
          To return products, call Papa
          & Barkley at 1 (844) 387-9877 or email: help@papaandbarkley.com
          to obtain a Return Merchandise Authorization (“RMA”).
          The RMA must be noted on the product shipped back to us.
          No returns will be accepted or refunds provided without an RMA.
          Once your return is received and inspected, you will receive an
          email to notify you that your returned item has been received.
          Papa & Barkley will also notify you of the approval or rejection
          of your refund. If you are approved, then your refund will be
          processed, and a credit will be applied to your credit card
          or original method of payment. Standard processing time of
          refunds varies and is determined by your credit card holder or bank.
        </Typography>,
        <Typography paragraph>
          <Typography>
            Damaged Products:
          </Typography>
          All Papa & Barkley products are tested for quality, and all
          shipments are carefully inspected before leaving the warehouse.
          Upon delivery of your order, please check the product carefully
          to ensure it has not been damaged during shipping. All claims
          for damaged product must be made within 48 hours of receipt.
          Please contact Papa & Barkley at help@papaandbarkley.com
          within that time and provide detailed information regarding
          any product damaged during shipping. Please include photos
          and a full description of the damages to the products.
        </Typography>,
      ],
    },
    {
      question: 'Ordering: Do you offer discreet shipping?',
      answerByParagraph: [
        <Typography paragraph>
          Our shipping labels do not contain any
          information about the product being a CBD product.
        </Typography>],
    },
    {
      question: 'Ordering: What if my package arrives damaged?',
      answerByParagraph: [
        <Typography paragraph>
          If your package arrives damaged please
          contact help@papaandbarkley.com or call
          1 (844) 234-8416
          and a Papa & Barkley customer service representatives
          will be more than happy to assist you.
        </Typography>],
    },
    {
      question: 'Ordering: What states do you ship to?',
      answerByParagraph: [
        <Typography paragraph>
          Papa & Barkley&apos;s National CBD product line is
          available for purchase and can ship to all 50 states.
        </Typography>],
    },
    {
      question: 'Ordering: I ordered the Papa & Barkley CBD Releaf Drops, but how can I enhance the flavor? ',
      answerByParagraph: [
        <Typography paragraph>
          Thank you for your order. Please visit &nbsp;
          <Link href="#/recipes">
            CBD Releaf Drop Recipe
          </Link>
          {' '}
&nbsp;
          for suggestions for flavor enhancements
        </Typography>],
    },
  ];
  return (
    <Box padding={4}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          spacing={2}
        >

          <Grid item padding={2}>
            <Typography
              align="center"
              style={{ color: '#93638b' }}
              variant="h4"
            >
              FAQ
            </Typography>
          </Grid>
          <Grid item>
            {
          questions.map((question) => (
            <Accordion key={question.question}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#93638b' }} />}
              >
                <Typography style={{ color: '#93638b' }} variant="h5">
                  {question.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ background: 'white' }}>
                <Grid container>
                  {
                   question.answerByParagraph.map((answer, index) => (
                     <Grid container key={index.toString()}>
                       { answer }
                     </Grid>
                   ))
                }
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        }
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQPage;
