import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../assets/wellCornerLogos/logoColor.png';

const BioModal = ({
  open, onClose, name, title, image, description,
}) => (
  <Dialog
    open={open}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container>

            <Grid item sm={4} xs={12}>
              <Box padding={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <img
                      alt={name}
                      src={image}
                      width="100%"
                      style={{ paddingBottom: 16 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      alt="logo"
                      src={logo}
                      width="100%"
                      style={{ paddingBottom: 16 }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Grid item xs={12}>
                <Box padding={2}>
                  <Typography
                    style={{ color: '#885880' }}
                    variant="h4"
                    align="left"
                  >
                    {name}
                  </Typography>
                  <Typography
                    style={{ color: '#885880', fontSize: '1.8rem' }}
                    variant="h4"
                    align="left"
                  >
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Box padding={2}>
                {description.map((paragraph) => (
                  <Typography paragraph key={paragraph}>
                    {paragraph}
                  </Typography>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>

  </Dialog>

);
BioModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)).isRequired,
};

export default BioModal;
