import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tableCellRoot: {
    borderColor: '#a5a5a5',
    borderStyle: 'solid',
    borderWidth: 1,
    '&.question': {
      width: theme.spacing(64),
    },
  },
}));

const OtherResultsTable = ({ answers }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              <Typography style={{ color: '#885880' }}>
                {answers.name}
              </Typography>
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              <Typography>
                Date
              </Typography>
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              <Typography>
                Values
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            answers.answers.map((answer) => (
              answer.responses.map((response, index) => {
                const background = index === 0 ? '#e2f0d5' : null;
                return (
                  <TableRow key={response.timestamp}>
                    {
                      index === 0 ? (
                        <TableCell
                          classes={{ root: clsx(classes.tableCellRoot, 'question') }}
                          rowSpan={answer.responses.length}
                        >
                          <Typography>
                            {answer.question_text}
                          </Typography>
                        </TableCell>
                      ) : null
                    }
                    <TableCell classes={{ root: classes.tableCellRoot }} style={{ background }}>
                      <Typography>
                        {moment(response.timestamp).format('MMMM Do YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }} style={{ background }}>
                      <Typography>
                        {response.values.length === 0 ? '-' : response.values.join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OtherResultsTable.propTypes = {
  answers: PropTypes.shape({
    name: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        question_text: PropTypes.string,
        responses: PropTypes.arrayOf(
          PropTypes.shape({ }),
        ),
      }),
    ),
  }).isRequired,
};

export default OtherResultsTable;
