import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const TermsOfUse = () => {
  const history = useHistory();
  return (
    <Box padding={4}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography
              style={{ color: '#93638b' }}
              align="center"
              variant="h4"
            >
              Terms of Use
            </Typography>
          </Grid>
          <Grid item>

            <p className="p1">
              <span className="s1">
                <strong>
                  wellCORNER, LLC

                  <span className="Apple-converted-space">&nbsp;&nbsp;</span>
                  Website Terms of Use

                </strong>
              </span>
            </p>
            <p className="p2">
              <br />
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Effective Date:</strong>
                &nbsp;
                June 18, 2022
              </span>
            </p>
            <p className="p4">
              <br />
            </p>
            <p className="p5">
              <span className="s2">
                These Terms of Use (&ldquo;

                <strong>Terms</strong>
                &rdquo;) of&nbsp;

              </span>
              <span className="s1">Cornerstone Specialty Network, LLC (“Wellcorner”, “our”, “us” or “we”) grants you access to our website located at https://www.wellcorner.com (the “Website”) conditioned on your acceptance of these Terms of Use (these “Terms of Use”).</span>
            </p>
            <p className="p4">
              <span className="s1">Please review THESE TERMS carefully. By using THE Site, you acknowledge that you accept the TERMS set forth herein. If you do not accept such TERMS, you may not access the Site.</span>
            </p>
            <p className="p4">
              <br />
            </p>
            <p className="p3">
              <span className="s1">
                <strong>
                  BINDING ARBITRATION

                  <span className="Apple-converted-space">&nbsp;</span>
                </strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">These Terms of Use provide that all disputes between you and us will be resolved by BINDING ARBITRATION. ACCORDINGLY, YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT (INCLUDING IN A CLASS ACTION PROCEEDING) to assert or defend your rights under these Terms of Use (except for matters that may be taken to small claims court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury and your claims cannot be brought as a class action. Please review the section of these Terms of Use titled “Dispute Resolution; Arbitration Agreement” for the details regarding your agreement to arbitrate any disputes with us.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Modifications to These Terms of Use</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">We reserve the right, in our sole discretion, to modify these Terms of Use at any time without notice to you. We will post all modifications to these Terms of Use on the Website and they will become effective immediately upon being posted to the Website. Your continued use of the Website following the posting of such modifications constitutes your acceptance of them. Therefore, you should check the Effective Date of these Terms of Use each time that you visit the Website and review any modifications made since the last time that you visited it.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Privacy Policy</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">We will treat all personal information that you choose to provide to us through the Website in accordance with our privacy policy located at </span>
              <Link
                href="#/privacypolicy"
                onClick={() => history.push('/privacypolicy')}
                style={{ color: '-webkit-link', textDecoration: 'underline' }}
              >
                https://www.wellcorner.com/#/privacypolicy
              </Link>
              <span className="s1">at the “Privacy Policy”). By using the Website, you consent to the privacy practices set forth in the Privacy Policy.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Modifications to the Website</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">We may terminate, change, suspend or discontinue any aspect of the Website at any time without notice to you.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Proprietary Materials</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">The Website contains forms, content, information, trademarks and other proprietary materials that belong to us and our licensors and that are protected by copyright, trademark and other intellectual property laws (collectively, “Proprietary Materials”).</span>
            </p>
            <p className="p6">
              <span className="s1">You should assume that everything on the Website is Proprietary Materials, and, except as otherwise explicitly provided on certain Proprietary Materials that you may access through the Website, you shall not reproduce, distribute, publicly display, publicly perform, prepare derivative works of or otherwise use or exploit Proprietary Materials in any way without our prior written consent. Commercial use of Proprietary Materials is strictly prohibited. All rights not expressly granted herein are reserved to us and our licensors.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Restrictions</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">You agree that you will not yourself or through any third party: (i) use the Website in a manner that violates applicable laws or the rights of third parties, including intellectual property laws and third-party rights with respect thereto; (ii) engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the Website or which may harm us or other users of the Website; (iii) violate the security of the Website, including by using any device, software or routine that interferes with the proper functioning of the Website, accessing or attempting to access any systems or servers on which the Website is hosted, modifying or altering the Website in any way or forging headers, misrepresenting your identity or otherwise manipulating identifiers to deceive others; (iv) use automated data extraction tools or manual processes to extract Proprietary Materials from the Website; or (v) except as otherwise explicitly provided on certain Proprietary Materials that you may access through the Website, remove, conceal or alter any identification, copyright or other proprietary rights notices or labels on the Website or Proprietary Materials.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Third-Party Websites</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">We may include links on the Website to third-party websites that we do not control or operate (each, a “Third-Party Website”). We are not responsible for any information, content, advertising, products, services or other materials on any Third-Party Website, and the presence of such links does not constitute our endorsement, approval or sponsorship of any Third-Party Website. If you choose to link to any Third-Party Website, you are doing so at your own risk and you will be subject to the Terms of Use of that website. Therefore, before interacting with any Third-Party Website, you should consult the legal terms governing the use of such Third-Party Website. We expressly disclaim any and all liability resulting from your use of any Third-Party Website.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Disclaimer</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">THE WEBSITE AND PROPRIETARY MATERIALS ARE PROVIDED “AS-IS”, “WHERE-IS” AND “AS-AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTIES OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WELLCORNER HEREBY EXPRESSLY DISCLAIMS ON BEHALF OF OURSELF AND OUR OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (COLLECTIVELY, THE “WELLCORNER PARTIES”) ANY AND ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NEITHER WELLCORNER NOR ANY OF THE WELLCORNER PARTIES REPRESENTS OR WARRANTS THAT THE WEBSITE WILL BE ERROR-FREE, THAT DEFECTS, IF ANY, WILL BE CORRECTED OR THAT THE WEBSITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; NOR DOES WELLCORNER OR ANY OF THE WELLCORNER PARTIES MAKE ANY REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS, QUALITY, PERFORMANCE OR SUITABILITY OF THE WEBSITE OR PROPRIETARY MATERIALS.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Limitation of Liability</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WELLCORNER OR ANY OF THE WELLCORNER PARTIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY (I) CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, INDIRECT OR SPECIAL DAMAGES OF ANY NATURE ARISING FROM BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE OR ANY OTHER LEGAL THEORY, WHETHER IN TORT OR CONTRACT, EVEN IF SUCH PARTY HAS BEEN APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING OR (II) DIRECT DAMAGES IN EXCESS OF ONE ($1) U.S. DOLLAR.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Indemnification</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">You shall indemnify, defend and hold harmless Wellcorner and the Wellcorner Parties from and against any and all claims, actions, damages, obligations, losses, liabilities, costs and expenses (including reasonable attorneys’ fees, costs of collection and other costs of defense) arising out of or relating to your use of the Website or Proprietary Materials or your violation of these Terms of Use.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Termination of these Terms of Use</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">We may, without notice to you, immediately terminate these Terms of Use if you breach these Terms of Use or engage in conduct that we, in our sole discretion, believe is in violation of applicable law or our rights or the rights of other users of the Website.</span>
            </p>
            <p className="p6">
              <span className="s1">Upon termination of these Terms of Use, your right to use the Website and Proprietary Materials will immediately terminate.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Complete Agreement</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">These Terms of Use and the Privacy Policy constitute the entire agreement between you and us with respect to the subject matter hereof and thereof and supersede all prior and contemporaneous agreements, whether oral or written, between us and you with respect to the same.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Dispute Resolution; Arbitration Agreement</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">We and you agree that all disputes arising from or relating to the Website or any relationship or dispute between you and us or you and any company or person employed by us, these Terms of Use and any policies or practices of any of the above-mentioned companies or persons (a “Dispute”) will only be resolved subject to FINAL AND BINDING ARBITRATION as set forth in this section, and may only be resolved through an individual arbitration governed by the Federal Arbitration Act to the maximum extent permitted by applicable law. You further agree that we shall not have a legal obligation to mitigate any of our potential or actual losses sustained hereunder</span>
            </p>
            <p className="p6">
              <span className="s1">WE AND YOU GIVE UP OUR RESPECTIVE RIGHTS TO GO TO COURT in connection with any Dispute and such rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY. NEITHER WE NOR YOU SHALL BRING OR PARTICIPATE IN ANY CLASS ACTION OR OTHER CLASS PROCEEDING IN CONNECTION WITH ANY DISPUTE. Neither we nor you agree to class arbitration or any other arbitration proceedings where a person brings a dispute as a representative of other persons.</span>
            </p>
            <p className="p6">
              <span className="s1">If we and you are unable to resolve a Dispute by informal means, the arbitration of that Dispute will be administered by the American Arbitration Association (AAA) in New Hope, New Jersey accordance with the Commercial Arbitration Rules. Any proceeding to enforce this arbitration agreement must be brought in the US District Court for the District of New Jersey or in any New Jersey state court of competent jurisdiction with venue lying in Bucks to the exclusion of all other forums.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Governing Law</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">All Disputes will be governed by and construed in accordance with the laws of the New Jersey without reference to the choice of law provisions of any jurisdiction.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Digital Millennium Copyright Act (DMCA) Compliance</strong>
              </span>
            </p>
            <p className="p6">
              <span className="s1">Wellcorner abides by the federal Digital Millennium Copyright Act (DMCA) by responding to notices of alleged infringement that comply with the DMCA and other applicable laws. If you believe that your work has been copied in a way that constitutes copyright infringement on the Website, please email our designated DMCA Agent at info@cornerstoneonc.com with the following information:</span>
            </p>
            <ol className="ol1">
              <ol className="ol2">
                <ul className="ul1">
                  <li className="li5">
                    <span className="s2">A physical or electronic signature of the person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</span>
                  </li>
                  <li className="li5">
                    <span className="s2">A specific description of the protected work claimed to have been infringed;</span>
                  </li>
                  <li className="li5">
                    <span className="s2">A specific description of where on the Website the material that is allegedly infringing is located;</span>
                  </li>
                  <li className="li5">
                    <span className="s2">Information reasonably sufficient to permit Wellcorner to contact the complaining party, such as an address, telephone number, and email address;</span>
                  </li>
                  <li className="li5">
                    <span className="s2">A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</span>
                  </li>
                  <li className="li5">
                    <span className="s2">A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</span>
                  </li>
                </ul>
              </ol>
            </ol>
            <p className="p3">
              <span className="s1">
                <strong>
                  Please note: The DMCA provides that you may be liable for damages
                  (including costs and attorneys&apos; fees) if you make a false or bad faith
                  allegation of copyright infringement by using this process.
                </strong>
              </span>
            </p>
            <p className="p4">
              <br />
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Severability</strong>
              </span>
            </p>
            <p className="p3">
              <span className="s1">If any provision of these Terms of Use is found to be invalid or unenforceable by a court of competent jurisdiction, the invalid or unenforceable provision will be deemed superseded by a valid and enforceable provision that most closely matches the intent of the original provision, and the remainder of these Terms of Use will remain in full force and effect.</span>
            </p>
            <p className="p3">
              <span className="s1">
                <strong>Contact Us</strong>
              </span>
            </p>
            <p className="p3">
              <span className="s1">If you have any questions about these Terms of Use, please contact us at info@cornerstoneonc.com or 325 West Bridge Street Unit # 173, New Hope, PA 18938.</span>
            </p>
            <p className="p4">
              <br />
            </p>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TermsOfUse;
