import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/wellCornerLogos/logoColor.png';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    marginRight: '20px',
  },
}));

const Footer = () => {
  const history = useHistory();
  const currentDate = new Date().getFullYear();

  const classes = useStyles();

  return (
    <Box className="no-print" padding={4}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          xs={12}
          md={8}
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              color="primary"
              onClick={() => history.push('/home')}
            >
              <a href="https://www.wellcorner.com/">
                <img
                  alt="wellcorner"
                  src={logo}
                  style={{
                    width: '100%',
                    maxWidth: '256px',
                  }}
                />
              </a>
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            style={{ margin: '40px 0 0' }}
          >
            <Grid
              item
              xs={6}
              sm={2}
              md={1}
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ display: 'none' }}
            >
              <Button
                color="primary"
                className={classes.button}
              >
                <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/about">CBD</a>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                minWidth: '187px',
              }}
            >
              <Button
                color="primary"
                href={window.location.hostname === 'survey.wellcorner.com'
                  ? 'https://wellcorner.com/categories.html'
                  : 'https://dev.wellcorner.com/categories.html'}
                className={classes.button}
              >
                Wellness Products
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={1}
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                minWidth: '115px',
              }}
            >
              <Button
                color="primary"
                className={classes.button}
              >
                <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/about">About Us</a>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={1}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Button
                color="primary"
                className={classes.button}
              >
                <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/faq">FAQ</a>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={1}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Button
                color="primary"
                className={classes.button}
              >
                <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/glossary">Glossary</a>
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            style={{ margin: '0 0 40px' }}
          >
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                minWidth: '152px',
              }}
            >

              <Button
                color="primary"
                className={classes.button}
              >
                <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/privacypolicy">Privacy Policy</a>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                minWidth: '50px',
              }}
            >

              <Button
                color="primary"
                onClick={() => history.push('/terms')}
                className={classes.button}
              >
                <a style={{ textDecoration: 'none', color: '#68923E' }} href="https://wellcorner.com/terms">Terms</a>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Typography align="center" style={{ fontSize: '10px', marginBottom: '0 0 14px' }}>
          The contents of this website are based on clinical research and reviewed by
          wellCORNER&apos;s Professional Advisory Board.
        </Typography>
        <Typography align="center" style={{ fontSize: '10px', margin: '0 0 14px' }}>
          &copy;
          {' '}
          {currentDate}
          {' '}
          Copyright Information
        </Typography>
      </Grid>
    </Box>
  );
};

export default Footer;
