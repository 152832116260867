import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Client from 'shopify-buy';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Api from '../../common/Api';
import MobileCartPage from './MobileCartPage';
import ItemQuantity from './ItemQuantity';
import { freeShippingValue } from '../../constants';
import GaApi from '../../analytics/gaApi';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '150px',
    height: '66px',
    fontSize: '20px',
    textTransform: 'inherit',
    borderRadius: '15px',
    margin: '0 50px 0 0',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
  buttonBack: {
    background: 'none',
    color: '#885880',
    boxShadow: 'none',
    fontSize: '20px',
    textTransform: 'inherit',
    fontWeight: 'bold',
    '&:hover': {
      background: 'none',
      color: '#885880',
      boxShadow: 'none',
      fontSize: '20px',
      textTransform: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
  popup: {
    position: 'absolute',
    bottom: '-65%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px 0 rgba(0,0,0,0.2)',
    zIndex: '1',
    width: '678px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  checkout: {
    width: '150px',
    height: '66px',
    fontSize: '20px',
    textTransform: 'inherit',
    background: '#885880',
    borderRadius: '10px',
    '&:hover': {
      background: '#885880',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
  buttonContinue: {
    border: 'none',
    color: '#885880',
    fontSize: '20px',
    textTransform: 'inherit',
    margin: '10px 0 0 40px',
    '&:hover': {
      background: 'none',
      color: '#885880',
      boxShadow: 'none',
      fontSize: '20px',
      textTransform: 'inherit',
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
}));

const CartPage = ({
  cart, onCartChange, shopifyClient, authorized,
}) => {
  const history = useHistory();
  const [subtotal, setSubtotal] = useState(0);
  const isMobileScreenSize = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [amountUntilFreeShipping, setAmountUntilFreeShipping] = useState(75);
  useEffect(() => {
    const val = Math.round((freeShippingValue - subtotal) * 100, 2) / 100;
    setAmountUntilFreeShipping(val);
  }, [subtotal]);
  useEffect(() => {
    const total = cart
      .map(({ quantity, variant }) => quantity * parseFloat(variant.price))
      .reduce((first, second) => first + second, 0);
    const roundedTotal = Math.round(total * 100, 2) / 100;
    setSubtotal(roundedTotal);
  }, [cart]);

  const [ShowPopup, setShowPopup] = useState(false);
  const classes = useStyles();

  return (
    <Box
      padding={4}
      style={{
        position: 'relative',
      }}
    >
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h4" style={{ color: '#885880', fontSize: '40px', textAlign: 'center' }}>
            Your Shopping Cart
          </Typography>
          <Typography
            variant="h6"
            style={{
              margin: '46px 0',
              color: '#68923E',
              fontSize: '20px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            *CBD products must be purchased separately from other wellCORNER products
          </Typography>
        </Grid>
        <Grid item>
          <Hidden smUp>
            <MobileCartPage
              cart={cart}
              onCartChange={onCartChange}
            />
          </Hidden>
          <Hidden xsDown>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderBottom: '1px solid #A5A5A5' }} />
                    <TableCell style={{ borderBottom: '1px solid #A5A5A5' }} />
                    <TableCell style={{ borderBottom: '1px solid #A5A5A5' }}>
                      <Typography align="center" style={{ fontSize: '20px', color: '#565656' }}>
                        Quantity
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #A5A5A5' }}>
                      <Typography align="center" style={{ fontSize: '20px', color: '#565656' }}>
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #A5A5A5' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                  cart.map((cartItem, index) => (
                    <TableRow key={cartItem.variant.id}>
                      <TableCell style={{ borderBottom: '1px solid #A5A5A5' }}>
                        <img
                          alt={cartItem.variant.altText}
                          src={cartItem.variant.image.src}
                          width={128}
                        />
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #A5A5A5' }}>
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                        >
                          <Grid item style={{ paddingBottom: 0 }}>
                            <Typography variant="h6" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                              {cartItem.product.title}
                            </Typography>
                          </Grid>
                          <Grid item style={{ padding: '0 8px' }}>
                            <Typography style={{ color: '#565656', fontSize: '20px' }}>
                              {cartItem.variant.title}
                            </Typography>
                          </Grid>
                          <Grid item style={{ padding: '0 8px' }}>
                            <Typography style={{
                              fontSize: '20px',
                              textDecorationLine: 'line-through',
                              textDecorationStyle: 'solid',
                            }}
                            >
                              {`MSRP: $${cartItem.variant.compareAtPrice}`}
                            </Typography>
                          </Grid>
                          <Grid item style={{ padding: '0 8px' }}>
                            <Typography variant="h6" style={{ color: '#D31C5C' }}>
                              {`Your price: $${cartItem.variant.price}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #A5A5A5' }}>
                        <ItemQuantity
                          displayQuantityLabel={false}
                          onSubtract={() => {
                            const updatedCart = [...cart];
                            const value = (updatedCart[index].quantity - 1);
                            if (value > -1) {
                              updatedCart[index].quantity = value;
                              onCartChange(updatedCart);
                            }
                          }}
                          quantity={cartItem.quantity}
                          onAdd={() => {
                            const updatedCart = [...cart];
                            const value = updatedCart[index].quantity + 1;
                            updatedCart[index].quantity = value;
                            onCartChange(updatedCart);
                          }}
                        />
                      </TableCell>
                      <TableCell style={{
                        borderBottom: '1px solid #A5A5A5',
                        fontSize: '20px',
                      }}
                      >
                        {`$${cartItem.variant.price}`}
                      </TableCell>
                      <TableCell style={{ borderBottom: '1px solid #A5A5A5' }}>
                        <IconButton
                          onClick={() => {
                            const updatedCart = cart.filter(({ variant }) => (
                              cartItem.variant.id !== variant.id
                            ));
                            onCartChange(updatedCart);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          <Grid
            item
            container
            spacing={2}
          >
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              style={{ paddingTop: '4%' }}
              xs={12}
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    fontSize: '25px',
                    fontWeight: '400',
                  }}
                >
                  {`Subtotal: $${subtotal}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{
                  fontSize: '15px',
                }}
                >
                  {
                  amountUntilFreeShipping > 0 ? `Add $${amountUntilFreeShipping} for Free Shipping`
                    : 'Congrats! Free Shipping!'
                }
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              spacing={2}
              container
              direction={isMobileScreenSize ? 'column' : 'row'}
              justify={isMobileScreenSize ? 'center' : 'flex-start'}
              alignItems={isMobileScreenSize ? 'stretch' : 'flex-start'}
              style={{ paddingTop: '4%' }}
            >
              <Grid item>
                <Button
                  color="primary"
                  fullWidth={isMobileScreenSize}
                  className={classes.checkout}
                  onClick={() => {
                    GaApi.sendEvent(
                      'Checkout',
                      'Checkout button pressed',
                    );
                    const input = { customAttributes: [{ key: 'Practice', value: sessionStorage.getItem('practiceSlug') ? sessionStorage.getItem('practiceSlug') : 'N/A' }] };
                    shopifyClient.checkout.create(input)
                      .then((checkout) => {
                        const lineItems = cart.filter((cartItem) => (
                          cartItem.quantity > 0
                        ))
                          .map((cartItem) => ({
                            variantId: cartItem.variant.id,
                            quantity: cartItem.quantity,
                          }));
                        return shopifyClient.checkout.addLineItems(checkout.id, lineItems);
                      }).then(({ webUrl }) => (
                        authorized ? Api.getShopifyMultipassUrl(webUrl) : webUrl
                      )).then((url) => {
                        window.location.replace(url);
                      });
                  }}
                  variant="contained"
                >
                  Checkout
                </Button>
              </Grid>
              <Grid item>
                <Button
                  fullWidth={isMobileScreenSize}
                  color="primary"
                  className={classes.buttonContinue}
                  onClick={() => setShowPopup(!ShowPopup)}
                  variant="outlined"
                >
                  Continue Shopping
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {ShowPopup && (
      <Grid
        className={classes.popup}
      >
        <Grid
          style={{
            padding: '15px 24px',
            textAlign: 'center',
            maxWidth: '678px',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setShowPopup(!ShowPopup)}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
            style={{
              position: 'absolute',
              right: '5px',
              top: '2px',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid>
            <Typography
              style={{
                color: '#885880',
                fontSize: '40px',
                margin: '30px 0',
              }}
            >
              Leaving CBD Shopping Cart
            </Typography>
          </Grid>

          <Grid>
            <Typography
              style={{
                color: '#885880',
                fontSize: '18px',
                margin: '0 auto 38px',
                lineHeight: '23px',
                maxWidth: '516px',
              }}
            >
              CBD products must be purchased in a separate CBD cart. If
              you are finished shopping for CBD, please complete checkout
              now.
            </Typography>
          </Grid>

          <Grid
            style={{
              color: '#565656',
              fontSize: '16px',
              lineHeight: '19px',
              maxWidth: '500px',
              margin: '0 auto 56px',
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                lineHeight: '19px',
              }}
            >
              Interested in other products? Click on ‘WELLCORNER PRODUCTS’
              in the menu bar; these products require checkout separate from aCBD.
            </Typography>
          </Grid>

          <Grid>
            <Grid
              style={{
                marginBottom: '30px',
              }}
            >
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push('/shop')}
                className={classes.button}
              >
                Continue
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setShowPopup(!ShowPopup)}
                className={classes.buttonBack}
              >
                Back to cart
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      )}
    </Box>
  );
};

CartPage.propTypes = {
  authorized: PropTypes.bool.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ }),
      variant: PropTypes.shape({ }),
      quantity: PropTypes.number,
    }),
  ).isRequired,
  onCartChange: PropTypes.func.isRequired,
  shopifyClient: PropTypes.instanceOf(Client).isRequired,
};

export default CartPage;
