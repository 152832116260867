import React, { useState, useEffect } from 'react';
import {
  HashRouter, Route, Switch, Redirect,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Client from 'shopify-buy';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Api from '../common/Api';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Home from '../components/landingPage/Home';
import Login from '../components/user/Login';
import Register from '../components/user/Register';
import AboutPage from '../components/AboutPage';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfUse from '../components/TermsOfUse';
import ShopPage from '../components/ecommerce/ShopPage';
import ProductPage from '../components/ecommerce/ProductPage';
import CartPage from '../components/ecommerce/CartPage';
import SurveyHistoryPage from '../components/survey/SurveyHistoryPage';
import SurveyPage from '../components/survey/SurveyPage';
import { theme } from './theme';
import AgeVerificationModal from '../components/landingPage/AgeVerificationModal';
import FAQPage from '../components/FAQPage';
import CbdPage from '../components/landingPage/CbdPage';
import GlossaryPage from '../components/GlossaryPage';
import HealthProfessionals from '../components/HealthProfessionals';
import Recipes from '../components/Recipes';

import ProfilePage from '../components/user/ProfilePage';
import ThankYouPage from '../components/survey/ThankYouPage';
import ResetPasswordPage from '../components/user/ResetPasswordPage';
import SurveyResults from '../components/survey/SurveyResults';
import JustAddedModal from './JustAddedModal';
import GaApi from '../analytics/gaApi';
import { nonPracticePaths } from '../constants';
import { PageView, initGA } from '../components/tracking';

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

export function wellcornerGetCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function wellcornerSetCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function GoogleAnalytics() {
  useEffect(() => {
    ReactGA.initialize(process.env.GA_TRACKING_ID);
  }, []);
  const { pathname } = useLocation();
  useEffect(() => {
    const firstPath = pathname.split('/')[1];
    if (nonPracticePaths.includes(firstPath)) {
      GaApi.pageView(pathname);
    }
  }, [pathname]);
  return null;
}

const AuthorizedRoute = ({
  authorized, path, component: Component, ...rest
}) => (
  <Route
    path={path}
    render={() => (
      authorized
        ? <Component {...rest} /> : <Redirect to="/home" />
    )}
  />
);

AuthorizedRoute.propTypes = {
  authorized: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

const client = Client.buildClient({
  domain: 'papaandbarkleycornerstone.myshopify.com',
  storefrontAccessToken: 'd48dc204340c8afe255deb74c16d9ecc',
});

const emptyAddedProduct = {
  product: { title: '' },
  variant: {
    price: 0, compareAtPrice: 0, title: '', altText: '', image: { src: '' },
  },
  quantity: 0,
};
const App = () => {
  /* initial modalOpen and authorized need to be set w/ sessionStorage.getItem('token')
  info to avoid race condition */
  const [modalOpen, setModalOpen] = useState(sessionStorage.getItem('token') === undefined || sessionStorage.getItem('token') === null);
  const [authorized, setAuthorized] = useState(sessionStorage.getItem('token') !== undefined && sessionStorage.getItem('token') !== null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [notification, setNotification] = useState({ message: '', severity: 'error' });
  const [cart, setCart] = useState([]);
  const [practices, setPractices] = useState(null);
  const [practice, setPractice] = useState(null);
  const [practiceSlug, setPracticeSlug] = useState(null);
  const [justAddedProductModalOpen, setJustAddedProductModalOpen] = useState(false);
  const [justAddedProduct, setJustAddedProduct] = useState(emptyAddedProduct);
  useEffect(() => {
    if (sessionStorage.getItem('token') !== undefined && sessionStorage.getItem('token') !== null) {
      setAuthorized(true);
      setModalOpen(false);
    }
  }, []);
  useEffect(() => {
    if (practiceSlug !== null && practiceSlug !== undefined) {
      sessionStorage.setItem('practiceSlug', practiceSlug);
    }
  }, [practiceSlug]);
  useEffect(() => {
    /* If practice changes, you know that the pathname was /#/<practice>/
    Therefore, send this page view/set storage item again if practice and
    practiceSlug match
    */
    if (practice !== null) {
      if (practiceSlug === practice.practice_slug) {
        sessionStorage.setItem('practiceSlug', practiceSlug);
        GaApi.pageView(practiceSlug, practiceSlug);
      }
    }
  }, [practice]);
  useEffect(() => {
    const subscription = Api.authorizedSubject.subscribe((value) => setAuthorized(value));
    return () => subscription.unsubscribe();
  }, []);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    client.product.fetchAll()
      .then(((results) => setProducts(results)));
  }, []);
  useEffect(() => {
    if (notification.message !== '') {
      setSnackbarOpen(true);
    }
  }, [notification]);
  useEffect(() => {
    Api.getPractices().then((values) => {
      setPractices(values);
    });
  }, []);
  useEffect(() => {
    if (practices !== null) {
      const defaultPractice = practices.find((value) => value.practice_slug === '');
      const result = practices.find((value) => value.practice_slug === practiceSlug);
      const next = result === undefined ? defaultPractice : result;
      setPractice(next);
    }
  }, [practices, practiceSlug]);

  useEffect(() => {
    initGA(process.env.GA_TRACKING_ID);
    PageView();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <CssBaseline />
        <HashRouter>
          <GoogleAnalytics />
          <ScrollToTop />
          <Navbar authorized={authorized} cart={cart} />
          <JustAddedModal
            open={justAddedProductModalOpen}
            justAddedProduct={justAddedProduct}
            onClose={() => {
              setJustAddedProductModalOpen(false);
              setJustAddedProduct(emptyAddedProduct);
            }}
          />
          <AgeVerificationModal
            open={modalOpen && wellcornerGetCookie('wellcorner_confirmed_age') !== 'true'}
            onClose={() => {
              wellcornerSetCookie('wellcorner_confirmed_age', 'true', 10);
              setModalOpen(false);
            }}
          />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route path="/home" render={() => <Home authorized={authorized} />} />
            <Route path="/login" render={() => <Login onNotification={setNotification} />} />
            <Route path="/resetpassword" component={ResetPasswordPage} />
            <Route
              path="/register"
              render={() => (
                practice === null
                  ? null
                  : <Register practiceId={practice.id} onNotification={setNotification} />
              )}
            />
            <Route path="/cbd" component={CbdPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsOfUse} />
            <Route path="/shop" render={() => <ShopPage products={products} />} />
            <Route
              path="/product/:id"
              render={() => (
                <ProductPage
                  products={products}
                  cart={cart}
                  onSubtractFromCart={(product, variant) => setCart((previousCart) => {
                    const updatedCart = [...previousCart];
                    const existingVariant = previousCart.find((cartItem) => (
                      cartItem.variant.id === variant.id
                    ));
                    if (existingVariant.quantity > 0) {
                      existingVariant.quantity -= 1;
                    }
                    return updatedCart;
                  })}
                  onAddToCart={(product, variant, productQuantity) => setCart((previousCart) => {
                    const updatedCart = [...previousCart];
                    const existingVariant = previousCart.find((cartItem) => (
                      cartItem.variant.id === variant.id
                    ));
                    if (existingVariant === undefined) {
                      updatedCart.push({ product, variant, quantity: productQuantity });
                      setJustAddedProduct({ product, variant, quantity: productQuantity });
                      setJustAddedProductModalOpen(true);
                    } else {
                      existingVariant.quantity += productQuantity;
                      setJustAddedProduct(existingVariant);
                      setJustAddedProductModalOpen(true);
                    }
                    return updatedCart;
                  })}
                />
              )}
            />
            <Route
              path="/cart"
              render={() => (
                <CartPage
                  authorized={authorized}
                  cart={cart}
                  onCartChange={setCart}
                  shopifyClient={client}
                />
              )}
            />
            <Route path="/faq" component={FAQPage} />
            <Route path="/glossary" component={GlossaryPage} />
            <Route path="/healthcareprofessionals" component={HealthProfessionals} />
            <Route path="/recipes" component={Recipes} />
            <AuthorizedRoute authorized={authorized} path="/profile" onNotification={setNotification} component={ProfilePage} />
            <AuthorizedRoute authorized={authorized} path="/thanks" component={ThankYouPage} />
            <AuthorizedRoute authorized={authorized} path="/surveys" component={SurveyHistoryPage} />
            <AuthorizedRoute authorized={authorized} path="/survey" component={SurveyPage} onNotification={setNotification} />
            <AuthorizedRoute authorized={authorized} path="/survey-results" component={SurveyResults} />
            <Route
              path="/:practiceSlug"
              render={() => <Home authorized={authorized} onPracticeSlug={setPracticeSlug} />}
            />
          </Switch>
          <Footer />
        </HashRouter>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ background: 'white' }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={notification.severity}
            variant="outlined"
          >
            <AlertTitle>
              <Typography variant="h5">
                {notification.severity.charAt(0).toUpperCase() + notification.severity.slice(1)}
              </Typography>
            </AlertTitle>
            <Typography variant="h6">
              {notification.message}
            </Typography>
          </Alert>
        </Snackbar>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
