import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Api from '../../common/Api';
import WelcomeSectionNew from './WelcomeSectionNew';
import JoinSectionNew from './JoinSectionNew';
import WellCornerMission from './WellCornerMission';

// eslint-disable-next-line react/prop-types
const Home = ({ authorized, onPracticeSlug }) => {
  const [, setQuestions] = useState([]);
  useRef(null);
  const { practiceSlug } = useParams();
  useEffect(() => {
    if (practiceSlug !== undefined) {
      onPracticeSlug(practiceSlug);
    }
  }, [practiceSlug]);
  useEffect(() => {
    Api.getInteractiveQuestions()
      .then((values) => setQuestions(values));
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ maxWidth: '1360px', padding: '0 15px', margin: 'auto' }}
    >
      <WelcomeSectionNew />
      <WellCornerMission />
      <JoinSectionNew authorized={authorized} />
    </Box>
  );
};

Home.propTypes = {
  authorized: PropTypes.bool.isRequired,
  onPracticeSlug: PropTypes.func,
};

Home.defaultProps = {
  onPracticeSlug: () => null,
};

export default Home;
