import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import heroImage from '../../assets/hero-balm-desktop.jpg';
import logoWhite from '../../assets/wellCornerLogos/logoWhiteNoPadding.png';

const useStyles = makeStyles((theme) => ({
  backgroundImagePositionStyle: {
    background: `url(${heroImage}) center/cover`,
    backgroundPosition: 'center top',
  },
  wellCornerAndLogoStyle: {
    paddingRight: '2%',
    [theme.breakpoints.up('md')]: {
      paddingRight: '8%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '12%',
    },
  },
  fontStyle: {
    fontSize: '3.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.5rem',
    },
  },
  imageStyle: {
    width: 275,
    [theme.breakpoints.up('md')]: {
      width: 430,
    },
    [theme.breakpoints.up('lg')]: {
      width: 475,
    },
  },
}));
const HeroSection = () => {
  const classes = useStyles();
  const matchesSizeSmallUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Box
          height={512}
          className={classes.backgroundImagePositionStyle}
          padding={matchesSizeSmallUp ? 4 : 1}
        >
          <Grid
            spacing={matchesSizeSmallUp ? 8 : 2}
            style={{ height: '100%' }}
            className={classes.wellCornerAndLogoStyle}
            container
            direction="row"
            justify="flex-end"
            alignItems="stretch"
          >
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              justify="space-evenly"
              alignItems="flex-end"
            >
              <Grid item>
                <Grid item>
                  <Typography align="right" color="textSecondary" variant="h4" style={{ fontSize: '3rem' }}>
                    Wellness around the corner
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <img alt="wellCORNER" src={logoWhite} className={classes.imageStyle} />
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          bgcolor="primary.main"
          padding={4}
        >
          <Typography
            align="center"
            color="textSecondary"
            variant="h4"
          >
            wellCORNER exclusively offers a line of naturally derived,
            hemp based products produced by
            Papa &amp; Barkley.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeroSection;
