import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import GaApi from '../../analytics/gaApi';
import Api from '../../common/Api';
import SurveyIntroduction from './SurveyIntroduction';
import SurveyCard from './SurveyCard';

const SurveyPage = ({ onNotification }) => {
  const [surveyPages, setSurveyPages] = useState(null);
  const [surveyPage, setSurveyPage] = useState(null);
  const [answers, setAnswers] = useState({ });
  const [pageIndex, setPageIndex] = useState(0);
  const matchesSizeSmallUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const history = useHistory();
  const startTime = moment();

  useEffect(() => {
    if (surveyPages !== null) {
      const nextSurveyPage = { ...surveyPages[pageIndex] };
      if (nextSurveyPage.category === 'severe' || nextSurveyPage.category === 'distress') {
        nextSurveyPage.questions = nextSurveyPage.questions.map((question, questionIndex) => {
          const key = Object.keys(question)[0];
          const filteredQuestions = question[key].filter((value, index) => {
            const parentQuestion = surveyPages[0].questions[questionIndex][key][index];
            return answers[parentQuestion.id] === parentQuestion.options[1].id;
          });
          return { [key]: filteredQuestions };
        });
      }
      setSurveyPage(nextSurveyPage);
    }
  }, [surveyPages, pageIndex, answers]);
  useEffect(() => {
    Api.getSurvey().then((value) => setSurveyPages(value.pages));
  }, []);
  useEffect(() => {
    if (surveyPages !== null) {
      const initialAnswers = surveyPages
        .flatMap((page) => page.questions)
        .flatMap((questions) => Object.values(questions))
        .flat()
        .reduce((accumulator, currentValue, index, array) => ({
          ...accumulator,
          [currentValue.id]: index === array.length - 1 ? [] : currentValue.options[0].id,
        }), { });
      setAnswers(initialAnswers);
    }
  }, [surveyPages]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageIndex]);
  return surveyPage === null ? null : (
    <Box padding={4}>
      <Container>
        <SurveyIntroduction pageIndex={pageIndex} />
        <SurveyCard
          answers={answers}
          surveyPage={surveyPage}
          onAnswer={(questionId, answer) => setAnswers((previous) => ({
            ...previous,
            [questionId]: answer,
          }))}
        />
        <Grid
          container
          direction="row"
          spacing={matchesSizeSmallUp ? 4 : 1}
          justify="center"
          alignItems="center"
          style={{ marginTop: 4 }}
        >
          <Grid item>
            <Button
              color="primary"
              disabled={pageIndex === 0}
              onClick={() => setPageIndex((previous) => previous - 1)}
              variant="outlined"
              size={matchesSizeSmallUp ? 'medium' : 'small'}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Typography color="primary">
              {`Page ${pageIndex + 1} of ${surveyPages.length}`}
            </Typography>
          </Grid>
          <Grid item>
            {
              pageIndex === surveyPages.length - 1 ? (
                <Button
                  color="primary"
                  size={matchesSizeSmallUp ? 'medium' : 'small'}
                  onClick={() => {
                    const currentAnswers = { ...answers };
                    const surveyQuestions = surveyPages
                      .slice(0, 3)
                      .map((page) => (
                        page.questions
                          .flatMap((question) => Object.values(question).flat())
                      ));
                    surveyQuestions[0].forEach((question, index) => {
                      if (answers[question.id] === question.options[0].id) {
                        const severeId = surveyQuestions[1][index].id;
                        const distressId = surveyQuestions[2][index].id;
                        delete currentAnswers[severeId];
                        delete currentAnswers[distressId];
                      }
                    });
                    const surveyAnswers = Object.keys(currentAnswers).map((key) => {
                      const choices = [].concat(answers[key])
                        .map((value) => ({ option_id: value }));
                      return {
                        question_id: key,
                        choices,
                      };
                    });
                    Api.sendSurveyAnswers(surveyAnswers)
                      .then(() => {
                        const endTime = moment();
                        const timeDiffSeconds = endTime.diff(startTime, 'seconds');
                        GaApi.endSurvey(timeDiffSeconds);
                        GaApi.sendEvent(
                          'SUBMIT_SURVEY',
                          'Submit Survey button pressed',
                        );
                        history.push('/thanks');
                      })
                      .catch(({ message }) => {
                        GaApi.sendEvent(
                          'SUBMIT_SURVEY',
                          'Submit Survey button pressed/failed',
                        );
                        onNotification({
                          message,
                          severity: 'error',
                        });
                      });
                  }}
                  variant="outlined"
                >
                  Submit
                </Button>
              ) : (
                <Button
                  size={matchesSizeSmallUp ? 'medium' : 'small'}
                  color="primary"
                  disabled={pageIndex === surveyPages.length - 1}
                  onClick={() => {
                    setPageIndex((previous) => previous + 1);
                  }}
                  variant="outlined"
                >
                  Continue
                </Button>
              )
            }
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

SurveyPage.propTypes = {
  onNotification: PropTypes.func.isRequired,
};

export default SurveyPage;
