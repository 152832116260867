import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Fade from '@material-ui/core/Fade';
import Api from '../common/Api';

const useStyles = makeStyles((theme) => ({
  answerContentStyle: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#68923E',
      color: 'white',
    },
    border: '2px dashed #68923E',
    [theme.breakpoints.down('sm')]: {
      height: 102, width: 204,
    },
    [theme.breakpoints.up('md')]: {
      height: 115, width: 230,
    },
    [theme.breakpoints.up('lg')]: {
      height: 128, width: 256,
    },
  },
}));

const LinearProgressWithLabel = ({ color, value }) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress color={color} value={value} variant="determinate" />
    </Box>
    <Box minWidth={35}>
      <Typography variant="body2">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

LinearProgressWithLabel.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const AnswerContent = ({
  answerCount,
  question,
  selections,
  onSelection,
}) => {
  const classes = useStyles();
  const [currentAnswerCount, setCurrentAnswerCount] = useState(0);
  useEffect(() => {
    const count = selections.filter((value) => value).length;
    setCurrentAnswerCount(count);
  }, [selections]);
  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        spacing={4}
        style={{
          padding: '0 0 24px',
        }}
      >
        {
            new Array(answerCount)
              .fill(null)
              .map((value, index) => index)
              .map((value, index) => (
                <Grid item key={value}>
                  <Box
                    bgcolor={index < currentAnswerCount ? 'primary.main' : '#b2bec3'}
                    height={8}
                    width={128}
                  />
                </Grid>
              ))
          }
      </Grid>
      <Grid
        container
        spacing={4}
        justify="center"
        alignItems="center"
      >
        {
            question.options.map((option, index) => (
              <Grid key={option.id} item>
                <Button
                  color="primary"
                  onClick={() => onSelection(index)}
                  className={classes.answerContentStyle}
                  style={selections[index] ? {
                    backgroundColor: '#68923E',
                    color: 'white',
                    width: '270px',
                    height: '70px',
                  }
                    : {
                      width: '270px',
                      height: '70px',
                      fontSize: '13px',
                      lineHeight: '16px',
                      padding: '8px 35px',
                    }}
                >
                  {option.option_text}
                </Button>
              </Grid>
            ))
          }
      </Grid>
    </>
  );
};

AnswerContent.propTypes = {
  answerCount: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
    question_text: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        option_text: PropTypes.string,
      }),
    ),
  }).isRequired,
  selections: PropTypes.arrayOf(
    PropTypes.bool,
  ).isRequired,
  onSelection: PropTypes.func.isRequired,
};

const ResponseRateContent = ({
  question,
  rates,
  selections,
}) => (
  <Grid
    container
    direction="row"
    justify="center"
    spacing={4}
  >
    {
        question.options.map((option, index) => (
          <Grid key={option.id} item xs={12} sm={6} md={3}>
            <Grid
              alignItems="center"
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Typography
                  align="center"
                  variant="h5"
                >
                  {option.option_text}
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <LinearProgressWithLabel
                  color={selections[index] ? 'primary' : 'secondary'}
                  value={rates[index]}
                />
              </Grid>
            </Grid>
          </Grid>
        ))
      }
  </Grid>
);

ResponseRateContent.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    question_text: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        option_text: PropTypes.string,
      }),
    ),
  }).isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  selections: PropTypes.arrayOf(
    PropTypes.bool,
  ).isRequired,
};

const InteractiveQuestion = ({
  question,
  answerCount,
  onAnswer,
  authorized,
}) => {
  const history = useHistory();
  const initialSelections = question.options.map(() => false);
  const [selections, setSelections] = useState(initialSelections);
  const [responseRates, setResponseRates] = useState(null);
  const [questionText, setQuestionText] = useState('');
  const [thankYouQuestionText, setThankYouQuestionText] = useState('');
  useEffect(() => {
    const answerIds = question.options
      .map(({ id }) => ({ option_id: id }))
      .filter((id, index) => selections[index]);
    if (answerIds.length === answerCount) {
      Api.sendInteractiveQuestion(question.id, answerIds)
        .then((updatedQuestions) => {
          const updatedQuestion = updatedQuestions.find(({ id }) => id === question.id);
          const rates = updatedQuestion.options.map((option) => option.response_rate);
          setResponseRates(rates);
        });
    }
  }, [selections]);
  useEffect(() => {
    const instruction = answerCount === 1 ? '' : `(select top ${answerCount} that apply)`;
    const text = `${question.question_text} ${instruction}:`;
    setQuestionText(text);
    if (answerCount === 1) {
      setThankYouQuestionText(`This is what others in the wellCORNER Community are saying 
        about why they are interested in hemp:`);
    } else {
      setThankYouQuestionText(`This is what others in the wellCORNER Community are saying 
        about what is important to them when using a hemp based product:`);
    }
  }, [question, answerCount]);
  return (
    <Card
      style={{
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)',
        maxWidth: '800px',
        padding: '0 15px',
      }}
    >
      <CardContent>
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="center"
          spacing={6}
        >
          <Grid
            item
            style={{
              padding: '35px 0 0',
            }}
          >
            {
                responseRates === null
                  ? (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      spacing={2}
                    >
                      <Grid item>
                        <NewReleasesIcon color="primary" fontSize="large" />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h4"
                          style={{
                            fontSize: '30px',
                            color: '#565656',
                          }}
                        >
                          {questionText}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Fade in={responseRates !== null} timeout={3000}>
                        <Grid item sm={8} xs={12}>
                          <Typography variant="h4" align="center">
                            Thank you for submitting your answer.
                          </Typography>
                          <Typography variant="h4" align="center">
                            {thankYouQuestionText}
                          </Typography>
                        </Grid>
                      </Fade>
                    </Grid>
                  )
              }

          </Grid>
          <Grid item>
            {
                responseRates === null
                  ? (
                    <AnswerContent
                      answerCount={answerCount}
                      question={question}
                      selections={selections}
                      onSelection={(index) => {
                        setSelections((previousSelections) => {
                          onAnswer();
                          const result = previousSelections.concat();
                          result[index] = !result[index];
                          return result;
                        });
                      }}
                    />
                  ) : null
              }
          </Grid>
          <Grid item>
            <Fade in={responseRates !== null} timeout={3000}>
              <Box>
                {
                    responseRates === null ? null : (
                      <ResponseRateContent
                        question={question}
                        rates={responseRates}
                        selections={selections}
                      />
                    )
                  }
              </Box>
            </Fade>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          disabled={responseRates === null}
          onClick={() => setResponseRates(null)}
        >
          Back
        </Button>
        { responseRates !== null && !authorized ? (
          <Fade in={responseRates !== null} timeout={3000}>
            <Button
              color="secondary"
              style={{ marginLeft: 'auto' }}
              onClick={() => history.push('/register')}
            >
              Register on this site to continue to provide valuable feedback
            </Button>
          </Fade>
        ) : null}
      </CardActions>
    </Card>
  );
};

InteractiveQuestion.propTypes = {
  authorized: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
    question_text: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        option_text: PropTypes.string,
      }),
    ),
  }).isRequired,
  answerCount: PropTypes.number,
  onAnswer: PropTypes.func,
};

InteractiveQuestion.defaultProps = {
  answerCount: 1,
  onAnswer: () => null,
};

export default InteractiveQuestion;
