import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import InteractiveQuestion from '../InteractiveQuestion';

const useStyles = makeStyles((theme) => ({
  factsGridItemStyle: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto', padding: '24px',
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: 1224,
      padding: '12px',
      width: '100%',
    },
  },
  bulletStyle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));

const HempInterestSectionNew = ({
  authorized, question,
}) => {
  const [showFacts, setShowFacts] = useState(false);
  const classes = useStyles();
  return (
    <Box padding={0}>
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={8}
        style={{
          margin: '100px 0',
          width: '100%',
        }}
      >
        <Grid
          item
          style={{
            padding: 0,
          }}
        >
          <InteractiveQuestion
            question={question}
            authorized={authorized}
            onAnswer={() => setShowFacts(true)}
          />
        </Grid>
        { showFacts ? (
          <Grid item xs={12} width="100%" className={classes.factsGridItemStyle}>
            <Card>
              <CardHeader
                title="Facts:"
                titleTypographyProps={{ color: 'primary' }}
              />
              <CardContent>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      <FiberManualRecordIcon color="primary" className={classes.bulletStyle} />
                    </ListItemIcon>
                    <Typography variant="h6" className={classes.bulletStyle}>
                      One in seven Americans say they personally use hemp based products
                    </Typography>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      <FiberManualRecordIcon color="primary" className={classes.bulletStyle} />
                    </ListItemIcon>
                    <Typography variant="h6" className={classes.bulletStyle}>
                      Since the 2018 Farm Bill  (2018 Agricultural Improvement Act) legalizing the
                      cultivation of hemp containing less than  0.3%  tetrahydrocannabinol (THC),
                      many CBD-based products have become available, but the large majority of
                      Americans are not yet familiar with them or don&apos;t use them
                    </Typography>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      <FiberManualRecordIcon color="primary" className={classes.bulletStyle} />
                    </ListItemIcon>
                    <Typography variant="h6" className={classes.bulletStyle}>
                      For cancer patients, it is important to inform your healthcare professional if
                      you plan to use, or are using hemp based products
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        ) : null }
      </Grid>
    </Box>
  );
};

HempInterestSectionNew.propTypes = {
  authorized: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({ }),
    ),
  }).isRequired,
};

export default HempInterestSectionNew;
