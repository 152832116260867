import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import logoWhite from '../../assets/wellCornerLogos/logoColor.png';

const AgeVerificationModal = ({ open, onClose }) => {
  const [ofAge, setOfAge] = useState(true);
  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={() => onClose()}
      fullScreen
    >
      <DialogContent style={{ backgroundColor: '#F5F5F5' }}>
        <Box padding={3} display="flex" alignItems="center">
          <Grid
            container
            spacing={4}
            direction="column"
            justify="center"
            alignItems="stretch"
          >
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography style={{ color: '#68923e' }} variant="h4">
                Welcome to
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <img
                alt="wellCORNER"
                src={logoWhite}
                width={300}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={10}
                md={8}
              >
                <Card
                  variant="outlined"
                  style={{ border: '1px solid #68923e' }}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justify="center"
                      alignItems="stretch"
                      style={{
                        padding: '2%',
                      }}
                    >
                      <Grid item xs={12} style={{ paddingBottom: '3%' }}>
                        <Typography
                          align="center"
                          variant="h5"
                          style={{
                            fontWeight: 'bold',
                            color: '#68923e',
                            fontSize: '1.25rem',
                          }}
                        >
                          Are you 18 years in age or older?
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        spacing={2}
                        justify="space-evenly"
                        alignItems="stretch"
                        style={{ paddingTop: '12%' }}
                      >
                        <Grid item xs={5} sm={4}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setOfAge(false)}
                            fullWidth
                          >
                            No
                          </Button>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="outlined"
                            onClick={() => onClose()}
                          >
                            Yes
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {
            ofAge ? null : (
              <Grid item>
                <Typography color="primary" align="center">
                  You are not old enough to enter this site.
                </Typography>
              </Grid>
            )
          }
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AgeVerificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AgeVerificationModal;
