import axios from 'axios';
import { Subject } from 'rxjs';

class Api {
  constructor() {
    this.token = sessionStorage.getItem('token');
    this.root = process.env.API_ROOT;
    this.authorizedSubject = new Subject();
  }

  getAuthOptions() {
    return {
      headers: {
        Authorization: `JWT ${this.token}`,
      },
    };
  }

  register({
    email,
    password,
    firstName,
    lastName,
    practiceId,
    birthday,
    hempTypes,
    referralId,
  }) {
    const payload = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      username: email,
      practice: practiceId,
      dob: birthday,
      hemp_types: hempTypes,
      referral: referralId,
    };
    return axios.post(`${this.root}/register-user/`, payload)
      .then((response) => {
        const { data } = response;
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('profileKey', data.key);
        this.token = data.token;
        this.authorizedSubject.next(true);
        return response.data;
      });
  }

  forgotPassword(email) {
    return axios.post(`${this.root}/forgot-password/`, { email_address: email })
      .then(({ data }) => data);
  }

  resetPassword(password, token) {
    return axios.post(`${this.root}/reset-password/`, { password, token })
      .then(({ data }) => data);
  }

  login(payload) {
    return axios.post(`${this.root}/api-token-auth/`, payload)
      .then(({ data }) => {
        const { token } = data;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('profileKey', data.key);
        this.token = token;
        this.authorizedSubject.next(true);
        return data;
      });
  }

  getProfile() {
    return axios.get(`${this.root}/profile/`, this.getAuthOptions())
      .then(({ data }) => data);
  }

  updateProfile(profile) {
    return axios.post(`${this.root}/profile/`, profile, this.getAuthOptions())
      .then(({ data }) => data);
  }

  refreshToken() {
    const payload = { token: this.token };
    return axios.post(`${this.root}/api-token-refresh/`, payload)
      .then(({ data }) => {
        const { token } = data;
        sessionStorage.setItem('token', token);
        this.token = token;
        return data;
      });
  }

  logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('profileKey');
    this.token = null;
    this.authorizedSubject.next(false);
  }

  getSurvey() {
    return axios.get(`${this.root}/survey_groups/`, this.getAuthOptions())
      .then(({ data }) => data);
  }

  getPractices() {
    return axios.get(`${this.root}/practices/`)
      .then(({ data }) => data);
  }

  sendSurveyAnswers(answers) {
    return axios.post(`${this.root}/survey_responses/`, { answers }, this.getAuthOptions())
      .then(({ data }) => data);
  }

  getSurveyAnswers(startDate, endDate) {
    const options = {
      ...this.getAuthOptions(),
      params: {
        timestamp__gte: startDate === null ? null : startDate.format('YYYY-MM-DD'),
        timestamp__lte: endDate === null ? null : endDate.format('YYYY-MM-DD'),
      },
    };
    return axios.get(`${this.root}/survey_answer/`, options)
      .then(({ data }) => data.data);
  }

  getInteractiveQuestions() {
    return axios.get(`${this.root}/interactive_questions/`)
      .then(({ data }) => data);
  }

  sendInteractiveQuestion(questionId, answerIds) {
    const payload = { answers: [{ question_id: questionId, choices: answerIds }] };
    return axios.post(`${this.root}/interactive-response/`, payload)
      .then(({ data }) => data.updated_questions);
  }

  isAuthorized() {
    return this.token !== null;
  }

  getShopifyMultipassUrl(checkoutUrl) {
    const options = {
      ...this.getAuthOptions(),
      params: {
        return_to: checkoutUrl,
      },
    };
    return axios.get(`${this.root}/shopify-multipass-url/`, options)
      .then(({ data }) => `https://${data.url}`);
  }

  getHempProductTypes() {
    return axios.get(`${this.root}/hemp-types/`)
      .then(({ data }) => data);
  }

  getReferralTypes() {
    return axios.get(`${this.root}/referral-types/`)
      .then(({ data }) => data);
  }
}

export default new Api();
