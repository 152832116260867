import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const SurveyIntroduction = ({ pageIndex }) => {
  const [title, setTitle] = useState(null);
  const [instructions, setInstructions] = useState(null);
  useEffect(() => {
    const nextTitle = pageIndex === 3
      ? 'Complementary and Alternative Medicine (CAM)'
      : 'My Wellness Survey';
    setTitle(nextTitle);
    switch (pageIndex) {
      case 0:
        setInstructions('We have listed symptoms below.  Please select "Yes" or "No" if you have had the symptom during the last week');
        break;
      case 1:
        setInstructions('The symptoms that you indicated you have experienced in the last week are listed below.  Let us know how SEVERE it was usually by selecting the appropriate response.');
        break;
      case 2:
        setInstructions('The symptoms that you indicated you have experienced in the last week are listed below.  Let us know how much it DISTRESSED or BOTHERED you by selecting the appropriate response.');
        break;
      case 3:
        setInstructions('Please indicate below which products or practices you have used in the past to promote your overall wellness.');
        break;
      default:
        setInstructions(null);
    }
  }, [pageIndex]);
  return (
    <Grid
      alignItems="center"
      container
      justify="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography
          style={{ color: '#93638b' }}
          variant="h4"
          align="center"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          style={{ color: '#93638b' }}
        >
          Instructions:
        </Typography>
        <Typography paragraph>
          {instructions}
        </Typography>
      </Grid>
    </Grid>
  );
};

SurveyIntroduction.propTypes = {
  pageIndex: PropTypes.number.isRequired,
};

export default SurveyIntroduction;
