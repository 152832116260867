import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const GlossaryPage = () => (
  <Box padding={4}>
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography
            style={{ color: '#93638b' }}
            align="center"
            variant="h4"
          >
            Glossary
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">Cannabinoids:</Typography>
          <Typography paragraph>
            Refers to any one of the 80-100 compounds found in the cannabis plant.
            This includes cannabidiol (CBD) and tetrahydrocannabinol (THC).
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">CBD:</Typography>
          <Typography paragraph>
            Cannabidiol is a naturally occurring compound found in higher amounts in
            hemp vs. marijuana plants and does not cause a person to feel high if consumed.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">Endocannabinoids:</Typography>
          <Typography paragraph>
            Refers to cannabinoids naturally produced in your body that can interact
            with parts of your endocannabinoid system.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">Hemp:</Typography>
          <Typography paragraph>
            Is a species of Cannabis plant that produces cannabinoids like CBD, as well as terpenes.
            The 2018 Farm Bill made it legal to cultivate hemp containing less than or equal
            to 0.3%.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">Terpenes:</Typography>
          <Typography paragraph>
            Terpenes are aromatic compounds found in many plants, though many people commonly
            associate them with cannabis because cannabis plants contain high concentrations
            of them. These aromatic compounds create the characteristic scent of many plants,
            such as cannabis, pine, and lavender, as well as fresh orange peel. In nature,
            these terpenes protect the plants from animal grazing or infectious germs.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">THC:</Typography>
          <Typography paragraph>
            Tetrahydrocannabinol is a naturally occurring compound and the main
            psychologically active compound found in cannabis plants.
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#93638b' }} variant="h5">Whole Plant Infusion:</Typography>
          <Typography paragraph>
            The process of CBD extraction that involves using the entire hemp plant,
            the sun-grown cannabis flowers, leaves, and stems are soaked in base oils
            to get the full spectrum of the plant’s biology.  This is an all-natural
            and solvent-free process, meaning chemicals are never introduced.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default GlossaryPage;
