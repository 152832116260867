import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InteractiveQuestion from '../InteractiveQuestionNew';

const useStyles = makeStyles((theme) => ({
  contentButton: {
    display: 'flex',
    maxWidth: '600px',
    margin: '150px auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  button: {
    width: '250px',
    height: '66px',
    fontSize: '20px',
    textTransform: 'inherit',
    borderRadius: '10px',
    margin: '0 50px 0 0',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 15px',
    },
  },
}));

const ProductImportanceSection = ({ authorized, question }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box
      padding={4}
    >
      <Grid
        alignItems="center"
        container
        direction="column"
        spacing={8}
      >
        <Grid
          item
          xs={12}
          style={{
            padding: '0',
          }}
        >
          <InteractiveQuestion
            authorized={authorized}
            answerCount={3}
            question={question}
          />
        </Grid>
        <Grid className={classes.contentButton}>
          <Grid>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push('/shop')}
              className={classes.button}
            >
              Explore Products
            </Button>
          </Grid>
          <Grid>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push('/register')}
              className={classes.button}
            >
              Join the community
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ProductImportanceSection.propTypes = {
  authorized: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({ }),
    ),
  }).isRequired,
};

export default ProductImportanceSection;
